<div>
  <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">${label}</label>
  <div class="flex flex-row relative">
    <input type="text" maxlength="7" value.bind="currentColor" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

    <div class="absolute right-2.5 top-1">
      <label for="${id}" class="cursor-pointer rounded-full my-auto h-8 w-8 flex" style.bind="`background-color: ${currentColor}`">
        <input id="${id}" type="color" class="invisible h-0 w-0" value.bind="currentColor" />

        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mx-auto my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" style.bind="{ color: isColorDark(currentColor) ? 'white' : 'black' }">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
        </svg>
      </label>
    </div>

  </div>
</div>