import { nhost } from 'lib/nhost';
import { ModelTypes } from '@zeus';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IGenericListConfig } from '@components/generic-list/interfaces/generic-filtered-interface';

interface IMediaProps {
  imageURL: string;
}

export class MediaPage {

  private selectedFiles: Blob[] | File = [];

  constructor(
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient,
  ) { }

  private config: IGenericListConfig = {
    tableName: 'userMedia',
    streamName: 'userMedia_stream',
    aggregateName: 'userMedia_aggregate',
    columns: ['id', 'fileId', 'updated_at', 'deleted'],
    filters: [],
    data: [],
    total: 0,
    perPage: 9,
    currentPage: 1,
  }

  attached() {
    const token = this.auth.getAccessToken();

    nhost.storage.setAccessToken(token);
  }

  private async getImage(fileId: string): Promise<string> {
    if (!fileId) return;

    const { presignedUrl, error } = await nhost.storage.getPresignedUrl({
      fileId: fileId,
      width: 200,
    })

    if (error) {
      console.error('Erro set image', error)
      return;
    }

    return presignedUrl.url;
  }

  private async filesChanged(event: Event) {
    if (!event) return;

    if (this.selectedFiles) {
      const fileResult = await nhost.storage.upload({ file: this.selectedFiles[0] })
      await this.zeusClient.chain('mutation')({
        insert_userMedia_one: [
          {
            object: {
              fileId: fileResult.fileMetadata.id,
            },
          },
          {
            id: true,
          },
        ]
      })
    }
  }

  async downloadImage(fileId: string) {
    const fileReference = await nhost.storage.getPresignedUrl({ fileId: fileId })
    await this.downloadFile(fileReference.presignedUrl.url)
  }

  async downloadFile(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'image.png';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }

  async deleteUserMedia(userMediaId: string) {
    await this.zeusClient.chain('mutation')({
      update_userMedia_by_pk: [
        {
          pk_columns: {
            id: userMediaId,
          },
          _set: {
            deleted: true,
          },
        },
        {
          id: true,
        },
      ]
    })
  }

}