import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ModelTypes, project_constraint, project_update_column } from '@zeus';
import { DI } from 'aurelia';

export interface ITextModuleService {
  brands(): Promise<Array<ModelTypes['brands']>>
  getProjectById(id: string): Promise<ModelTypes["project"]>
  products(): Promise<Array<ModelTypes['products']>>
  audiences(): Promise<Array<ModelTypes['audiences']>>
  insertProject(command: ModelTypes['project_insert_input']): Promise<ModelTypes["project"]>
}

class TextModuleService implements ITextModuleService {

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  async getProjectById(id: string): Promise<ModelTypes["project"]> {
    const result = await this.zeusClient.chain('query')({
      project_by_pk: [
        { id: id },
        {
          id: true,
          blogTitle: true,
          blogContent: true,
          featuredImageFileId: true
        }
      ]
    });
    return result?.project_by_pk as ModelTypes["project"];
  }

  async brands(): Promise<Array<ModelTypes['brands']>> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.brands as Array<ModelTypes['brands']>;
  }

  async products(): Promise<Array<ModelTypes['products']>> {
    const result = await this.zeusClient.chain('query')({
      products: [
        {},
        {
          id: true,
          alias: true,
        },
      ]
    });

    return result?.products as Array<ModelTypes['products']>;
  }

  async audiences(): Promise<Array<ModelTypes['audiences']>> {
    const result = await this.zeusClient.chain('query')({
      audiences: [
        {},
        {
          id: true,
          brandName: true,
        },
      ]
    });

    return result?.audiences as Array<ModelTypes['audiences']>;
  }

  async insertProject(command: ModelTypes['project_insert_input']): Promise<ModelTypes["project"]> {
    const result = await this.zeusClient.chain('mutation')({
      insert_project_one: [
        {
          object: {
            id: command.id,
            alias: command.alias,
            rawText: command.rawText,
            blogTitle: command.blogTitle,
            blogContent: command.blogContent,
            featuredImageFileId: command.featuredImageFileId,
            type: 'text',
            creditPrice: 0,
          },
          on_conflict: {
            constraint: project_constraint.project_pkey,
            update_columns: [
              project_update_column.id,
              project_update_column.alias,
              project_update_column.rawText,
              project_update_column.blogTitle,
              project_update_column.blogContent,
              project_update_column.featuredImageFileId,
              project_update_column.type,
              project_update_column.creditPrice,
            ]
          }
        },
        {
          id: true
        }
      ]
    });

    return result?.insert_project_one as ModelTypes["project"];
  }

}

export const ITextModuleService = DI.createInterface<ITextModuleService>(x => x.singleton(TextModuleService));
