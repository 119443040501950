<import from="../spinner-button/spinner-button-component" />

<div class="fixed -translate-y-1/2 -translate-x-1/2 top-2/4 left-1/2 w-full h-full overflow-auto bg-black/50 z-50">
  <div class="flex flex-col justify-center items-center p-8">

    <!-- beggin::card -->
    <div class="card md:w-3/4 w-full mx-auto p-4 mt-8 rounded flex flex-col gap-2">

      <!-- beggin::step -->
      <div class="flex rounded-md justify-center grow" role="group">
        <template repeat.for="step of steps">
          <button type="button" class="inline-flex items-center justify-center w-full px-4 py-3 mb-6 text-sm font-medium text-[#2F81F9] bg-gray-100 border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ${step.index === 0 ? 'rounded-l-lg' : ''} ${step.index === steps.length - 1 ? 'rounded-r-lg' : ''} ${step.index == currentStepIndex ? 'bg-secondary-600 text-[#FFF]' : ''} ${currentStepIndex > step.index && step.index < steps.length -1 ? 'text-[#2F81F9]' : '' }">
            ${step.stepTitle}
          </button>
        </template>
      </div>
      <!-- end::step -->

      <!-- beggin::step 01 -->
      <div class="flex flex-col gap-4 mb-6" if.bind="currentStepIndex == 0">

        <div class="flex md:flex-row flex-col flex-auto gap-4">
          <div class="flex-1">
            <label for="name" class="form-label">Seu nome</label>
            <validation-container>
              <input type="text" name="name" id="name" autocomplete="off" value.bind="stepOne.name & validate" class="form-input" required>
            </validation-container>
          </div>
          <div class="flex-1">
            <label for="brandName" class="form-label">Nome da marca</label>
            <validation-container>
              <input type="text" name="brandName" id="brandName" autocomplete="off" value.bind="stepOne.brandName & validate" class="form-input" required>
            </validation-container>
          </div>
        </div>

        <div class="flex md:flex-row flex-col flex-auto gap-4">
          <div class="flex-1">
            <label for="siteUrl" class="form-label">Site da sua empresa</label>
            <validation-container>
              <input type="text" siteUrl="siteUrl" id="siteUrl" autocomplete="off" value.bind="stepOne.siteUrl & validate" class="form-input" required>
            </validation-container>
          </div>
          <div class="flex-1">
            <label for="companySegment" class="form-label">Qual segmento representa melhor a sua atividade?</label>
            <validation-container>
              <select id="companySegment" value.bind="stepOne.companySegment & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of companySegmentData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
        </div>

        <div class="flex md:flex-row flex-col flex-auto gap-4">
          <div class="flex-1">
            <label for="productOrService" class="form-label">Como você definiria o seu produto final ou modelo de negócio?</label>
            <validation-container>
              <select id="productOrService" value.bind="stepOne.productOrService & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of productOrServiceData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>

          <div class="flex-1">
            <label for="staffCount" class="form-label">Número de funcionários</label>
            <validation-container>
              <select id="staffCount" value.bind="stepOne.staffCount & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of staffCountData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
        </div>

      </div>
      <!-- end::step 01 -->

      <!-- beggin::step 02 -->
      <div class="flex flex-col gap-4 mb-6" if.bind="currentStepIndex == 1">

        <div class="flex md:flex-row flex-col flex-auto gap-4">
          <div class="flex-1">
            <label for="expectation" class="form-label">Qual é a sua principal expectativa com a Copymagico?</label>
            <validation-container>
              <select id="expectation" value.bind="stepTwo.expectation & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of expectationData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <div class="flex-1">
            <label for="marketingObjective" class="form-label">Qual destes se aproxima do seu objetivo de marketing?</label>
            <validation-container>
              <select id="marketingObjective" value.bind="stepTwo.marketingObjective & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of marketingObjectiveData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
        </div>

        <div class="flex md:flex-row flex-col flex-auto gap-4">
          <div class="flex-1">
            <label for="sellOnCopyMagico" class="form-label">Gostaria de oferecer o seu produto/serviço para outros usuários e empresas da Copymagico?</label>
            <validation-container>
              <select id="sellOnCopyMagico" value.bind="stepTwo.sellOnCopyMagico & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of yesOrNoData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <div class="flex-1">
            <label for="networkingOnCopyMagico" class="form-label">Gostaria de fazer networking com especialistas, empresas e outros usuários da Copymagico?</label>
            <validation-container>
              <select id="networkingOnCopyMagico" value.bind="stepTwo.networkingOnCopyMagico & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of yesOrNoData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
        </div>

        <div class="flex md:flex-row flex-col flex-auto gap-4">
          <div class="flex-1">
            <label for="importantSocialNetworkForBusiness" class="form-label">Qual é a rede social mais importante para o seu negócio?</label>
            <validation-container>
              <select id="importantSocialNetworkForBusiness" value.bind="stepTwo.importantSocialNetworkForBusiness & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of socialNetworksData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <div class="flex-1">
            <label for="investInPaidChannels" class="form-label">Você investe em canais pagos como Google ou Meta Ads?</label>
            <validation-container>
              <select id="investInPaidChannels" value.bind="stepTwo.investInPaidChannels & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of yesOrNoData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
        </div>
      </div>
      <!-- end::step 02 -->

      <!-- beggin::step 03 -->
      <div class="flex flex-col gap-4 mb-6" if.bind="currentStepIndex == 2">
        <h2 class="text-sm text-gray-500 dark:text-gray-400 mx-10 text-center">Na copymagico além de automatizar o marketing e a criação de conteúdos, você recebe recompensas por suas ações!</h2>

        <div class="flex flex-col md:flex-row gap-5">
          <div class="card flex-1 p-4">
            <div class="flex flex-col justify-center items-center">
              <img src="assets/images/undraw_dash_left.svg" alt="undraw" class="h-24 md:h-56 mt-6 pb-1">
              <span class="text-sm font-normal text-gray-400 dark:text-gray-400 text-center pb-2"><span class="bg-secondary-600 text-white text-xs font-medium px-2.5 py-0.5 rounded">Em breve</span> - Afiliado Copymagico: Indique a copy e receba um percentual do valor investido pelos seus indicados</span>
            </div>
          </div>
          <div class="card flex-1 p-4">
            <div class="flex flex-col justify-center items-center">
              <img src="assets/images/undraw_dash_half.svg" alt="undraw" class="h-24 md:h-56 mt-6 pb-1">
              <span class="text-sm font-normal text-gray-400 dark:text-gray-400 text-center pb-2"><span class="bg-secondary-600 text-white text-xs font-medium px-2.5 py-0.5 rounded">Em breve</span> - Quantum Insights! Responda nossos quizzes e receba créditos para usar em criações</span>
            </div>
          </div>
          <div class="card flex-1 p-4" style="border-color: rgb(47 129 249 / 1) !important; border-width: 1px !important;">
            <div class="flex flex-col justify-center items-center">
              <img src="assets/images/undraw_dash_right.svg" alt="undraw" class="h-24 md:h-56 mt-6 pb-1">
              <span class="text-sm font-normal text-gray-400 dark:text-gray-400 text-center pb-2">Você acabar de receber <span class="font-bold text-[#2F81F9]">R$20,00</span> em créditos para realizar suas primeiras criações!</span>
            </div>
          </div>
        </div>

      </div>
      <!-- end::step 03 -->

      <!-- beggin::buttons -->
      <div class="flex">
        <button if.bind="currentStepIndex > 0" click.trigger="previousStep()" type="button" class="text-white bg-neutral-500 hover:bg-neutral-600 focus:ring-4 focus:ring-neutral-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-neutral-600 dark:hover:bg-neutral-500 focus:outline-none dark:focus:ring-neutral-600">Voltar</button>
        <button if.bind="currentStepIndex < steps.length-1" click.trigger="saveStep()" type="button" class="ml-auto btn-secondary">Avançar</button>
        <spinner-button-component class="ml-auto" if.bind="currentStepIndex == 2" label="Explorar a ferramenta" btn-class="btn-secondary" trigger-function.bind="() => finishOnBoard()"></spinner-button-component>
      </div>
      <!-- beggin::buttons -->

    </div>
    <!-- end::card -->

  </div>
</div>