<import from='@components/undraw/undraw-component' />
<import from='@components/card-title/card-title-component' />
<import from='@components/generic-list/generic-list-component' />
<import from='@components/promise-bind/pending/pending-component' />

<import from='./components/table-actions/table-actions-component' />

<section>
  <card-title-component title-id="projetos"></card-title-component>

  <generic-list-component config.bind="config">
    <div au-slot="body">

      <template if.bind="$host.data.length === 0">
        <undraw-component message="Nenhum criação encontrada"></undraw-component>
      </template>
      <template else>
        <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mb-6">
          <template repeat.for="project of $host.data">

            <!-- begin::card -->
            <div class="card h-full rounded-2xl border border-gray-100 dark:border-gray-700">
              <div class="flex flex-col h-full">

                <!-- begin::img -->
                <div class="relative mx-4 mt-4 overflow-hidden text-white shadow-lg rounded-xl bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40 transition-all duration-500 min-h-[10rem]">

                  <template promise.bind="setImage(project.previewImageFileId)">
                    <let data.bind="null" err.bind="null"></let>

                    <template pending>
                      <pending-component class="flex justify-center items-center h-full"></pending-component>
                    </template>

                    <template then.from-view="data">
                      <img class="w-full h-40 object-cover object-center rounded-lg shadow-md" src="${data ?? 'https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80'}" alt="cover" />
                    </template>
                  </template>

                  <div class="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-tr from-transparent via-transparent to-black/60"></div>

                  <div class="!absolute top-4 left-4 transition-all">
                    <div class="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden rounded-xl bg-gray-100 dark:bg-gray-700 border">
                      <i class="${setIconByType(project.type)} text-base text-gray-500 dark:text-gray-400"></i>
                    </div>
                  </div>

                  <div class="!absolute top-5 right-4 transition-all">
                    <span class="inline-flex items-center text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full capitalize ${project.status == 'scheduled' ? 'bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-300' : project.status == 'draft' ? 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-300' : 'bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-300'}">
                      <span class="w-2 h-2 mr-1 rounded-full ${project.status == 'scheduled' ? 'bg-amber-500' : project.status == 'draft' ? 'bg-red-500' : 'bg-emerald-500'}"></span>
                      <span t="${'project.status.' + project.status}"></span>
                    </span>
                  </div>

                </div>

                <div class="flex items-start justify-start p-4 grow">
                  <h3 class="text-xs font-semibold tracking-tight text-gray-900 dark:text-white line-clamp-2">${project.alias ?? '...'}</h3>
                </div>

                <div class="flex flex-wrap justify-between items-center p-4 gap-3">
                  <div class="flex flex-col gap-1 w-fit">
                    <div class="flex flex-col justify-start items-start gap-1">
                      <span class="text-xs font-normal text-gray-700 dark:text-gray-400">Publicação:</span>
                      <template switch.bind="project.status & signal:'parameterChanged' ">
                        <span case="scheduled" class="text-xs font-normal text-gray-500 text-right">${project.scheduledDate | dateTime:I18N.getLocale().replace('_', '-') & signal:'parameterChanged'}</span>
                        <span case="published" class="text-xs font-normal text-gray-500 text-right">${project.publishDate | dateTime:I18N.getLocale().replace('_', '-') & signal:'parameterChanged'}</span>
                        <span default-case class="text-xs font-normal text-gray-500 text-right">...</span>
                      </template>
                    </div>
                  </div>

                  <table-actions-component project.bind="project" delete-callback.bind="() => deleteProject(project.id)"></table-actions-component>
                </div>
              </div>

            </div>
            <!-- end::card -->

          </template>
        </div>
      </template>

    </div>
  </generic-list-component>

</section>