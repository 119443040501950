import { newInstanceForScope } from '@aurelia/kernel';
import { IRouter, Navigation, RoutingInstruction } from '@aurelia/router';
import { watch } from "@aurelia/runtime-html";
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { QuillComponent } from '@components/quill/quill-component';
import { ToastType } from '@components/toast/events/toast-event';
import { ToastComponent } from '@components/toast/toast-component';
import { ModelTypes } from '@zeus';
import { IEventAggregator, bindable } from 'aurelia';
import { nhost } from 'lib/nhost';
import { ITextModuleService } from './services/text-module-services';

export class TextModule {
  @bindable content: string;
  @bindable blogTitle: string;

  @bindable imageFile: File;
  @bindable imageFileId: string;

  private textId: string;
  private base64Data: string;
  private imageResponse: string;

  quillRef: QuillComponent;

  private brandId: string = null;
  private productId: string = null;
  private audienceId: string = null;

  private productsTagify: Tagify;
  private audiencesTagify: Tagify;

  private prompt: string;
  private selectedBrandId: string;
  private selectedProductId: string;
  private selectedAudienceId: string;

  private brands: ModelTypes['brands'][];
  private products: ModelTypes['products'][];
  private audiences: ModelTypes['audiences'][];

  constructor(
    @IRouter private router: IRouter,
    @IEventAggregator readonly ea: IEventAggregator,
    @ITextModuleService private service: ITextModuleService,
    @IValidationRules readonly validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) readonly validationController: IValidationController
  ) {
    validationRules
      .on(this)
      .ensure('blogTitle')
      .required().withMessage('Informe o nome o titulo')

      .ensure('content')
      .required().withMessage('Informe o texto')

      .ensure('imageFile')
      .required().withMessage('Insira uma imagem')
  }

  async loading(params: { id: string; }, instruction: RoutingInstruction, navigation: Navigation) {
    if (params.id) {
      this.textId = params.id;
    }
  }

  async attached() {
    if (this.textId) {
      const response = await this.service.getProjectById(this.textId);

      this.content = response?.blogContent;
      this.blogTitle = response?.blogTitle;

      if (!response.featuredImageFileId) return

      const featureImageFileId = response?.featuredImageFileId
      const { presignedUrl } = await nhost.storage.getPresignedUrl({ fileId: featureImageFileId });

      this.base64Data = presignedUrl?.url
    }

    this.handleLoadData();

    this.selectedBrandId = this.brandId;
    this.selectedProductId = this.productId;
    this.selectedAudienceId = this.audienceId;
  }

  private async handleLoadData(): Promise<void> {
    this.brands = await this.service.brands();
    this.products = await this.service.products();
    this.audiences = await this.service.audiences();
  }

  async addProjectBlog() {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    try {
      const imageBannerBlogResponse = await nhost.storage.upload({ file: this.imageFile });

      this.content = this.quillRef.quill.root.innerHTML;

      const resultMutation = await this.service.insertProject({
        id: this.textId,
        alias: '',
        rawText: '',
        blogTitle: this.blogTitle,
        blogContent: this.content,
        featuredImageFileId: imageBannerBlogResponse.fileMetadata.id,
      });

      this.showMessage({ title: 'Sucesso', message: 'Processo de execução está tudo certo', type: ToastType.SUCCESS });

      this.router.load(`/text/${resultMutation.id}`)
    } catch (e) {
      this.showMessage({ title: 'Erro', message: e, type: ToastType.DANGER });
    }
  }

  async filesChanged(event: { target: { files: FileList }; }) {
    if (!event.target.files) return
    this.imageFile = event.target.files[0]

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.base64Data = fileReader.result as string;
    }
    if (this.imageFile) {
      fileReader.readAsDataURL(this.imageFile);
    }
  }

  private async getFileURL(fileId: string): Promise<string> {
    const { presignedUrl } = await nhost.storage.getPresignedUrl({ fileId, width: 100, height: 100 })

    return presignedUrl.url;
  }

  private showMessage({ title, message, type }: { title: string, message: string, type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type
      }
    });
  }
}