import { ModelTypes } from '@zeus';
import { IProductsService } from './services/products-service';

export class ProductsPage {

  private products: ModelTypes["products"][] = [];

  constructor(@IProductsService private service: IProductsService) { }

  attached() {
    this.getProducts();
  }

  private async getProducts(): Promise<void> {
    this.products = await this.service.getProducts();
  }
}