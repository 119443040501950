import Aurelia from 'aurelia';
import { RouterConfiguration } from '@aurelia/router';
import { ValidationHtmlConfiguration } from '@aurelia/validation-html';
import Fetch from 'i18next-fetch-backend';
import { nhost } from 'lib/nhost';
import { AuthChangeEvent, NhostSession } from '@nhost/nhost-js';
import { AppTask, IListenerBindingOptions, SVGAnalyzer } from '@aurelia/runtime-html';

import { I18nConfiguration } from '@aurelia/i18n';
import { availableLanguages } from 'common/data';
import { DateFormatConverter, DateTimeFormatConverter } from '@converters/date-format-converter';
import * as Sentry from "@sentry/browser";
import { ValueFromParameters } from '@converters/value-from-parameters-converter';
import { LabelFromParameters } from '@converters/label-from-parameters-converter';
import { FilterLocalePrompt } from '@converters/filter-locale-prompt-converter';
import { CurrencyFormatConverter } from '@converters/currency-format-converter';
import { CreditToBRLFormatConverter } from '@converters/credit-to-brl-format-converter';
import { ShortDateFormatConverter } from '@converters/short-date-format-converter';
import { TimeagoConverter } from '@converters/timeago-converter';
import { CodigoMDEConverter } from '@converters/codigo-mde-converter';
import { DialogDefaultConfiguration } from '@aurelia/dialog';
import { MyApp } from 'app/my-app';
import { AuthenticationHook } from 'common/hooks/authentication-hook';
import { PhoneNumberConverter } from '@converters/phone-number-converter';
import { InputMaskAttribute } from '@attributes/input-mask-attribute';

// Sentry.init({
//   dsn: "https://b2530268c0ebcd58db9e03a418efc3a4@o4506242743599104.ingest.sentry.io/4506242748907520",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const appName = 'copymagico'
const locales = window.navigator.languages;

let currentLocale = localStorage.getItem('locale')

if (!currentLocale) {
  currentLocale = locales[0].replace('-', '_');

  const selectedLanguageFriendlyName = availableLanguages.find(l => l.code === currentLocale)

  if (selectedLanguageFriendlyName) {
    localStorage.setItem('locale', currentLocale);
  } else {
    currentLocale = 'en_US'
    localStorage.setItem('locale', currentLocale);
  }
}

let initialized = false;

nhost.auth.onAuthStateChanged((event: AuthChangeEvent, session: NhostSession) => {
  if (!initialized) {
    Aurelia
      .register(SVGAnalyzer)
      .register(DialogDefaultConfiguration)
      .register(AppTask.creating(IListenerBindingOptions, options => options.prevent = true))
      .register(InputMaskAttribute)
      .register(PhoneNumberConverter, CodigoMDEConverter, ShortDateFormatConverter, TimeagoConverter, DateFormatConverter, DateTimeFormatConverter, CreditToBRLFormatConverter, CurrencyFormatConverter, FilterLocalePrompt, LabelFromParameters, ValueFromParameters)
      .register(ValidationHtmlConfiguration.customize((options) => {
        options.SubscriberCustomElementTemplate = `
      <slot></slot>
      <slot name='secondary'>
        <span style="color:red; font-size: 10px;" repeat.for="error of errors">
          \${error.result.message}
        </span>
      </slot>
    `;
      }))
      .register(I18nConfiguration.customize((options) => {
        options.initOptions = {
          defaultNS: 'translation',
          fallbackLng: 'en_US',
          lng: currentLocale,
          plugins: [Fetch],
          backend: {
            loadPath: 'assets/locales/{{lng}}/{{ns}}.json'
          }
        };
      }))
      .register(AuthenticationHook)
      .register(RouterConfiguration.customize({ useUrlFragmentHash: false, title: appName + ' ${appTitleSeparator} ${componentTitles}' }))
      .app(MyApp)
      .start();

    initialized = true;
  }
});