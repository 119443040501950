import { IExportToWodpress, IInsertOrUpdateAudience, IInsertOrUpdateProduct, IPostToFacebook, IPostToInstagram, IPostToLinkedIn, IPublishOrScheduleProject, ISaveUserOnboardingStepOne, ISaveUserOnboardingStepTwo, ISelectNewDisplay, IUpsertAudience, IUpsertBrand } from '@interfaces/graphql-service/commands';
import { DI, IEventAggregator } from 'aurelia';
import { createClient, type Sink } from 'graphql-ws';
import { GeneratedPromptByTemplate_select_column, ModelTypes, SubscriptionThunder, Thunder, ValueTypes, audiences_select_column, brands_select_column, chainOptions, order_by, products_select_column, userAppIntegration_select_column, userOnboarding_constraint, userOnboarding_update_column } from '../../../utils/generated/zeus';
import { IAuthService } from '../../interfaces/auth-service/auth-service-interface';

export class ZeusClient {

	public userMetadataSubscription;
	public userSubscription: ModelTypes["users"];
	public userDetails: ModelTypes["userMetadata"] | undefined;
	public generatedPromptByTemplateSubscription: ModelTypes["GeneratedPromptByTemplate"];
	public consumptionByChannelViewSubscription: Array<ModelTypes["consumptionByChannelView"]>;

	private toastId = Math.random().toString(36).substring(2, 9);

	constructor(
		@IAuthService private Auth: IAuthService,
		@IEventAggregator readonly ea: IEventAggregator
	) { }

	restart() {
		this.chain = Thunder(async (query, variables: Record<string, unknown> = {}) => {
			const bearer = this.Auth.getAccessToken();

			const response = await fetch(
				'https://uhgcmhbgmvuhtnaryxwr.hasura.sa-east-1.nhost.run/v1/graphql',
				{
					body: JSON.stringify({ query, variables }),

					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${bearer}`
					},
				},
			);

			if (!response.ok) {
				return new Promise((resolve, reject) => {
					response
						.text()
						.then((text) => {
							try {
								reject(JSON.parse(text));
							} catch (err) {
								reject(text);
							}
						})
						.catch(reject);
				});
			}

			const json = await response.json();
			return json.data;
		});

		this.apiSubscription = (options: chainOptions) => {

			const client = createClient({
				url: String(options[0]),
				connectionParams: () => {
					const token = this.Auth.getAccessToken();
					return {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				}
			});

			const ws = new Proxy(
				{
					close: () => client.dispose(),
				} as WebSocket,
				{
					get(target, key) {
						if (key === 'close') return target.close;
						throw new Error(`Unimplemented property '${String(key)}', only 'close()' is available.`);
					},
				},
			);

			return (query: string) => {
				let onMessage: ((event: any) => void) | undefined;
				let onError: Sink['error'] | undefined;
				let onClose: Sink['complete'] | undefined;

				client.subscribe(
					{ query },
					{
						next({ data }) {
							onMessage && onMessage(data);
						},
						error(error) {

							onError && onError(error);
						},
						complete() {
							onClose && onClose();
						},
					},
				);

				return {
					ws,
					on(listener: typeof onMessage) {
						onMessage = listener;
					},
					error(listener: typeof onError) {
						onError = listener;
					},
					open(listener: (socket: unknown) => void) {
						client.on('opened', listener);
					},
					off(listener: typeof onClose) {
						onClose = listener;
					},
				};
			};
		};

		this.SubscriptionEx = (...options: chainOptions) => SubscriptionThunder(this.apiSubscription(options));

		this.subscription = this.SubscriptionEx('wss://uhgcmhbgmvuhtnaryxwr.hasura.sa-east-1.nhost.run/v1/graphql', {
			headers: {
				'Authorization': `Bearer ${this.Auth.getAccessToken()}`
			},
		});
	}

	chain = Thunder(async (query, variables: Record<string, unknown> = {}) => {
		const bearer = this.Auth.getAccessToken();

		const response = await fetch(
			'https://uhgcmhbgmvuhtnaryxwr.hasura.sa-east-1.nhost.run/v1/graphql',
			{
				body: JSON.stringify({ query, variables }),
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${bearer}`
				},
			},
		);
		if (!response.ok) {
			return new Promise((resolve, reject) => {
				response
					.text()
					.then((text) => {
						try {
							reject(JSON.parse(text));
						} catch (err) {
							reject(text);
						}
					})
					.catch(reject);
			});
		}


		const json = await response.json();

		return json.data;
	});

	action = Thunder(async (query) => {
		const bearer = this.Auth.getAccessToken();

		const response = await fetch(
			'https://uhgcmhbgmvuhtnaryxwr.hasura.sa-east-1.nhost.run/v1/graphql',
			{
				body: JSON.stringify({ query }),
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${bearer}`
				},
			},
		);
		if (!response.ok) {
			return new Promise((resolve, reject) => {
				response
					.text()
					.then((text) => {
						try {
							reject(JSON.parse(text));
						} catch (err) {
							reject(text);
						}
					})
					.catch(reject);
			});
		}


		const json = await response.json();
		return {
			data: json.data,
			error: json.errors
		};
	});

	apiSubscription = (options: chainOptions) => {

		const client = createClient({
			url: String(options[0]),
			connectionParams: () => {
				const token = this.Auth.getAccessToken();
				return {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			}
		});

		const ws = new Proxy(
			{
				close: () => client.dispose(),
			} as WebSocket,
			{
				get(target, key) {
					if (key === 'close') return target.close;
					throw new Error(`Unimplemented property '${String(key)}', only 'close()' is available.`);
				},
			},
		);

		return (query: string) => {
			let onMessage: ((event: any) => void) | undefined;
			let onError: Sink['error'] | undefined;
			let onClose: Sink['complete'] | undefined;

			client.subscribe(
				{ query },
				{
					next({ data }) {
						onMessage && onMessage(data);
					},
					error(error) {
						onError && onError(error);
					},
					complete() {
						onClose && onClose();
					},
				},
			);

			return {
				ws,
				on(listener: typeof onMessage) {
					onMessage = listener;
				},
				error(listener: typeof onError) {
					onError = listener;
				},
				open(listener: (socket: unknown) => void) {
					client.on('opened', listener);
				},
				off(listener: typeof onClose) {
					onClose = listener;
				},
			};
		};
	};

	SubscriptionEx = (...options: chainOptions) => SubscriptionThunder(this.apiSubscription(options));

	subscription = this.SubscriptionEx('wss://uhgcmhbgmvuhtnaryxwr.hasura.sa-east-1.nhost.run/v1/graphql', {
		headers: {
			'Authorization': `Bearer ${this.Auth.getAccessToken()}`
		},
	});

	async getUserSubscription(): Promise<void> {
		const user = this.Auth.getUser();

		if (!user) return;

		const result = this.subscription('subscription')({
			user: [
				{ id: user.id },
				{
					id: true,
					avatarUrl: true,
					displayName: true,
					email: true,
				}
			]
		});

		result.on(({ user }: { user: ModelTypes["users"] | undefined }) => {
			this.userSubscription = user;
		});
	}

	async getCoursesByCategory(categoryId: string): Promise<Array<ModelTypes["Course"]>> {
		const result = await this.chain('query')({
			Course: [
				{ where: { courseCategoryId: { _eq: +categoryId } } },
				{
					id: true,
					name: true,
					description: true,
					imageUrl: true
				}]
		});

		return result.Course as Array<ModelTypes["Course"]>;
	}

	async getTextParameters(): Promise<Array<ModelTypes["TextGeneratorOption"]>> {
		const result = await this.chain('query')({
			TextGeneratorOption: [
				{},
				{
					id: true,
					Label: [{ path: '$' }, true],
					popover_tooltip: [{ path: '$' }, true],
					TextGeneratorOptionEntries: [
						{},
						{
							id: true,
							Label: [{ path: '$' }, true],
						}
					]
				}
			],
		});

		return result.TextGeneratorOption as Array<ModelTypes["TextGeneratorOption"]>;
	}

	async getPrompt(prompt: string): Promise<ModelTypes["PromptOutput"] | undefined> {
		const result = await this.chain('mutation')({
			purePrompt: [{ prompt: prompt }, { text: true }]
		})

		return result.purePrompt;
	}

	async getTextTemplates(offset: number, limit: number, channelId: string): Promise<Array<ModelTypes["TextPromptTemplate"]>> {
		const result = await this.chain('query')(
			{
				TextPromptTemplate: [
					{
						limit: limit,
						offset: offset,
						...(channelId && channelId != '') && { where: { channel: { _eq: channelId } } },
						order_by: [{ flexible: order_by.desc }, { name: order_by.asc }],
					},
					{
						id: true,
						name: true,
						description: true,
						definitions: [{ path: '$' }, true],
						icon: true,
						categoryId: true,
						saleCreditPrice: true,
						creditPrice: true,
						channel: true,
						TextTemplateClientCategory: {
							displayName: true,
							iconClass: true,
						},
						displayDimensionId: true,
						flexible: true,
					}
				]
			}
		)

		return result.TextPromptTemplate as Array<ModelTypes["TextPromptTemplate"]>;
	}

	async getTextTemplateById(id: string): Promise<ModelTypes["TextPromptTemplate"] | undefined> {
		const result = await this.chain('query')({
			TextPromptTemplate_by_pk: [
				{ id: id },
				{
					name: true,
					description: true,
					id: true,
					definitions: [{ path: '$' }, true]
				}
			]
		});

		return result.TextPromptTemplate_by_pk as ModelTypes["TextPromptTemplate"];
	}

	async getTextTemplateCategoriesView(): Promise<Array<ModelTypes["categorysummaryview"]>> {
		const result = await this.chain('query')({
			categorysummaryview: [
				{},
				{
					categoryName: true,
					id: true,
					count: true,
				}
			]
		});

		return result.categorysummaryview;
	}

	async getPaymentUrl(price_id: string): Promise<ModelTypes["GetPaymentUrlResponse"]> {
		const result = await this.chain('query')({
			getPaymentUrl: [
				{
					command: {
						email: 'luis.abe@gmail.com',
						price_id: price_id,
						user_id: 'wolo',
					}
				},
				{
					url: true,
				}
			]
		});

		return result.getPaymentUrl as ModelTypes["GetPaymentUrlResponse"];
	}

	async searchImagesFromPexels({ query, locale, orientationType, page }: { query: string, locale: string, orientationType: string, page: number }): Promise<ModelTypes["SearchImagesFromPexelsResponse"]> {

		if (!query) return;

		const result = await this.chain('query')({
			searchImagesFromPexels: [
				{
					query: query,
					locale,
					orientation: orientationType,
					page: page
				},
				{
					images: {
						id: true,
						url: true,
						small: true,
						photographer: true,
						photographer_url: true,
						height: true,
						width: true,
						alt: true,
					}
				}
			]
		});

		return result?.searchImagesFromPexels as ModelTypes["SearchImagesFromPexelsResponse"];
	}

	async generateImageFromPrompt(query: string): Promise<ModelTypes["GenerateImageFromPromptOutput"]> {
		const result = await this.chain('query')({
			generateImageFromPrompt: [
				{
					command: {
						query: query,
						provider: 'midjourney',
					},
				},
				{
					images: {
						id: true,
						url: true,
					}
				}
			]
		});

		return result.generateImageFromPrompt as ModelTypes["GenerateImageFromPromptOutput"];
	}

	async generateFromTextTemplate(templateId: string, selectedParameters: { [key: string]: string }, templateVariables: { [key: string]: string }, locale: string): Promise<ModelTypes["GenerateFromTextTemplateOutput"]> {

		const digeestedSelectedParameters = Object.keys(selectedParameters).map((key) => {
			return {
				key: key,
				value: selectedParameters[key]
			}
		});

		const digeestedTemplateVariables = Object.keys(templateVariables).map((key) => {
			return {
				key: key,
				value: templateVariables[key]
			}
		});

		const result = await this.chain('mutation')({
			generateFromTextTemplate: [
				{
					command: {
						templateId: templateId,
						selectedParameters: digeestedSelectedParameters,
						templateVariables: digeestedTemplateVariables,
						locale: locale,
					}
				},
				{
					text: true,
				}
			]
		});

		return result.generateFromTextTemplate as ModelTypes["GenerateFromTextTemplateOutput"];
	}

	async userDetailsSubscription(): Promise<void> {
		const user = this.Auth.getUser();

		if (!user) return;

		this.userMetadataSubscription = this.subscription('subscription')({
			userMetadata_by_pk: [
				{ id: user.id },
				{
					id: true,
					stripeSubscriptionItem: true,
					onboardCompleted: true,
					creditBalance: true,
					stripePriceId: true,
					pricingPlan: {
						creditUnitDiscount: true,
					}
					// generatingNextMonthCalendarFromOnboard: true,
				}
			]
		});

		this.userMetadataSubscription.error((error: any) => {
			console.log('userMetadataSubscription error', error);
		});


		this.userMetadataSubscription.on(({ userMetadata_by_pk }: { userMetadata_by_pk: ModelTypes["userMetadata"] | undefined }) => {
			this.userDetails = userMetadata_by_pk;

			// if (users_by_pk.generatingNextMonthCalendarFromOnboard === true) {
			// 	this.ea.publish(ToastEvent.SHOW, {
			// 		id: `generatingNextMonthCalendarFromOnboard-${this.toastId}`,
			// 		title: 'Generating',
			// 		message: 'Generating next month calendar from onboard',
			// 		type: ToastType.GENERATE,
			// 	})
			// } else if (users_by_pk.generatingNextMonthCalendarFromOnboard === false) {
			// 	this.ea.publish(ToastEvent.CLOSE, {
			// 		id: `generatingNextMonthCalendarFromOnboard-${this.toastId}`,
			// 	})
			// }
		});
	}

	async getManageSubscription(): Promise<ModelTypes["ManageSubscriptionOutput"]> {
		const result = await this.chain('query')({
			manageSubscription: {
				url: true,
			}
		});

		return result.manageSubscription as ModelTypes["ManageSubscriptionOutput"];
	}

	async getAppIntegrations(): Promise<Array<ModelTypes["AppIntegration"]>> {
		const result = await this.chain('query')({
			AppIntegration: [
				{},
				{
					id: true,
					icon: true,
					name: true,
					description: true,
					type: true,
					configParams: [{ path: '$' }, true],
				}
			]
		});

		return result.AppIntegration as Array<ModelTypes["AppIntegration"]>;
	}

	async getUserAppIntegrations(): Promise<Array<ModelTypes["userAppIntegration"]>> {
		const result = await this.chain('query')({
			userAppIntegration: [
				{},
				{
					id: true,
					integrationType: true,
					alias: true,
					params: [{ path: '$' }, true]
				}
			]
		});

		return result.userAppIntegration as Array<ModelTypes["userAppIntegration"]>;
	}

	async deleteUserAppIntegrationById(id: string): Promise<ModelTypes["userAppIntegration"] | undefined> {
		const result = await this.chain('mutation')({
			update_userAppIntegration_by_pk: [
				{
					pk_columns: { id: id },
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_userAppIntegration_by_pk as ModelTypes["userAppIntegration"];
	}

	async getUserAppIntegrationAggregate(): Promise<number> {
		const result = await this.chain('query')({
			userAppIntegration_aggregate: [
				{
					where: { deleted: { _eq: false } }
				},
				{
					aggregate: {
						count: [
							{ columns: [userAppIntegration_select_column.id] },
							true
						]
					}
				}
			]
		});

		return result.userAppIntegration_aggregate.aggregate.count as number;
	}

	async insertUserAppIntegration(type: string, brandId: string, params: any): Promise<ModelTypes["userAppIntegration"] | undefined> {
		const result = await this.chain('mutation')({
			insert_userAppIntegration_one: [
				{
					object: {
						integrationType: type,
						brandId: brandId,
						params: JSON.stringify(params),
					},
				},
				{
					id: true,
				}
			],
		});

		return result.insert_userAppIntegration_one as ModelTypes["userAppIntegration"];
	}

	async exportToWodpress(command: IExportToWodpress): Promise<ModelTypes["ExportToWordpressOutput"]> {
		const result = await this.chain('mutation')({
			exportToWordpress: [
				{
					command: command as ValueTypes["ExportToWordpressInput"],
				},
				{
					url: true,
				}
			]
		});

		return result.exportToWordpress as ModelTypes["ExportToWordpressOutput"];
	}

	async getWordpressCategories(integrationId: string): Promise<ModelTypes["GetWordpressCategoriesOutput"]> {
		const result = await this.chain('query')({
			getWordpressCategories: [
				{
					command: {
						integrationId: integrationId
					}
				},
				{
					categories: {
						id: true,
						name: true,
					}
				}
			]
		});

		return result.getWordpressCategories as ModelTypes["GetWordpressCategoriesOutput"];
	}

	async getWordpressTags(integrationId: string): Promise<ModelTypes["GetWordpressTagsOutput"]> {
		const result = await this.chain('query')({
			getWordpressTags: [
				{
					command: {
						integrationId: integrationId
					}
				},
				{
					tags: {
						id: true,
						name: true,
					}
				}
			]
		});

		return result.getWordpressTags as ModelTypes["GetWordpressTagsOutput"];
	}

	async getEvents(startDate: string, endDate: string): Promise<Array<ModelTypes["GeneratedPromptByTemplate"]>> {
		const result = await this.chain('query')({
			GeneratedPromptByTemplate: [
				{
					where: {
						_and:
							[
								{
									created_at: {
										_gte: startDate
									}
								},
								{
									created_at: {
										_lte: endDate
									}
								},
								{
									created_by: {
										_eq: this.Auth.getUser().id
									}
								}
							],
					},
				},
				{
					result: true,
					created_at: true,
					created_by: true,
					id: true,
					alias: true,
					scheduledDate: true,
					channel: true,
					status: true,
					TextPromptTemplate: {
						name: true,
					}
				}
			]
		});

		return result?.GeneratedPromptByTemplate as Array<ModelTypes["GeneratedPromptByTemplate"]>;
	}

	async getUserOnboarding(): Promise<ModelTypes["userOnboarding"] | undefined> {
		const result = await this.chain('query')({
			userOnboarding_by_pk: [
				{ userId: this.Auth.getUser().id },
				{
					userId: true,
					name: true,
					phone: true,
					brandName: true,
					siteUrl: true,
					companySegment: true,
					productOrService: true,
					staffCount: true,
					marketingObjective: true,
					expectation: true,
					sellOnCopyMagico: true,
					networkingOnCopyMagico: true,
					importantSocialNetworkForBusiness: true,
					investInPaidChannels: true,
					done: true,
					currentStep: true,
				}
			]
		})

		return result.userOnboarding_by_pk;
	}

	async saveUserOnboardingStepOne({ ...command }: ISaveUserOnboardingStepOne): Promise<ModelTypes["userOnboarding"] | undefined> {
		const result = await this.chain('mutation')({
			insert_userOnboarding_one: [
				{
					object: {
						userId: this.Auth.getUser().id,
						name: command.name,
						brandName: command.brandName,
						companySegment: command.companySegment,
						siteUrl: command.siteUrl,
						productOrService: command.productOrService,
						staffCount: command.staffCount,
						currentStep: 1,
						done: false,
					},
					on_conflict: {
						constraint: userOnboarding_constraint.userOnboarding_pkey,
						update_columns: [
							userOnboarding_update_column.userId,
							userOnboarding_update_column.name,
							userOnboarding_update_column.brandName,
							userOnboarding_update_column.companySegment,
							userOnboarding_update_column.siteUrl,
							userOnboarding_update_column.productOrService,
							userOnboarding_update_column.staffCount,
							userOnboarding_update_column.currentStep,
							userOnboarding_update_column.done,
						]
					}
				},
				{
					currentStep: true,
				}
			]
		})

		return result.insert_userOnboarding_one as ModelTypes["userOnboarding"] | undefined;
	}

	async saveUserOnboardingStepTwo({ ...command }: ISaveUserOnboardingStepTwo): Promise<ModelTypes["userOnboarding"] | undefined> {
		const result = await this.chain('mutation')({
			update_userOnboarding_by_pk: [
				{
					pk_columns: {
						userId: this.Auth.getUser().id
					},
					_set: {
						userId: this.Auth.getUser().id,
						marketingObjective: command.marketingObjective,
						expectation: command.expectation,
						sellOnCopyMagico: command.sellOnCopyMagico,
						networkingOnCopyMagico: command.networkingOnCopyMagico,
						importantSocialNetworkForBusiness: command.importantSocialNetworkForBusiness,
						investInPaidChannels: command.investInPaidChannels,
						currentStep: 2,
						done: false,
					}
				},
				{
					currentStep: true,
				}

			]
		})

		return result.update_userOnboarding_by_pk as ModelTypes["userOnboarding"] | undefined;
	}

	async getSerpStatTestOutput(): Promise<ModelTypes["SerpStatTestOutput"] | undefined> {
		const result = await this.chain('query')({
			serpStatTest: [
				{ command: { domain: 'solstar.com.br' }, },
				{
					data: {
						id: true,
						result: {
							summary_info: {
								left_lines: true,
								page: true,
							},
							data: {
								keywords: true,
								domain: true,
								ads: true,
								ad_keywords: true,
								down_keywords: true,
								ads_dynamic: true,
								new_keywords: true,
								keywords_dynamic: true,
								out_keywords: true,
								prev_date: true,

								rised_keywords: true,
								traff: true,
								visible: true,
								traff_dynamic: true,
								visible_dynamic: true,
							}
						},
					},
				}
			]
		})

		return result.serpStatTest;
	}

	async generateNextMonthCalendar(campaignObjective: string): Promise<void> {
		await this.chain('mutation')({
			generateNextMonthCalendar: [
				{
					command: {
						campaignObjective: campaignObjective
					}
				},
				true
			]
		});
	}

	async getBrandById(id: string): Promise<ModelTypes["brands"] | undefined> {
		const result = await this.chain('query')({
			brands_by_pk: [
				{ id: id },
				{
					id: true,
					brandName: true,
					slogan: true,
					voiceTone: true,
					segment: true,
					productOrService: true,
					fontPrimary: true,
					fontSecondary: true,
					isBrand: true,
					personality: true,
					colors: [{ path: '$', }, true],
					archtypeAdherence: [{ path: '$', }, true],
				}
			]
		});

		return result.brands_by_pk as ModelTypes["brands"] | undefined;
	}

	async getBrandAggregate(): Promise<number> {
		const result = await this.chain('query')({
			brands_aggregate: [
				{
					where: { deleted: { _eq: false } }
				},
				{
					aggregate: {
						count: [
							{ columns: [brands_select_column.id] },
							true
						]
					}
				}
			]
		});

		return result.brands_aggregate.aggregate.count as number;
	}

	async upsertBrand(command: IUpsertBrand): Promise<ModelTypes["upsertBrandOutput"] | undefined> {
		const result = await this.chain('mutation')({
			upsertBrand: [
				{ ...command },
				{
					id: true,
					brandName: true,
					isBrand: true,
					slogan: true,
					segment: true,
					productOrService: true,
					fontPrimary: true,
					fontSecondary: true,
					voiceTone: true,
					personality: true,
					colors: true,
					archtypeAdherence: true,
				}
			]
		});

		return result.upsertBrand as ModelTypes["upsertBrandOutput"];
	}

	async deleteBrandtById(id: string): Promise<ModelTypes["brands"] | undefined> {
		const result = await this.chain('mutation')({
			update_brands_by_pk: [
				{
					pk_columns: { id: id },
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_brands_by_pk as ModelTypes["brands"];
	}

	async finishOnBoard(): Promise<ModelTypes["FinishOnboardOutput"] | undefined> {
		const result = await this.chain('mutation')({
			finishOnboard:
			{
				success: true,
			}
		});

		return result.finishOnboard
	}

	async getAudiences(): Promise<Array<ModelTypes["audiences"]>> {
		const result = await this.chain('query')({
			audiences: [
				{},
				{
					id: true,
					brandName: true,
					segment: true,
					productOrService: true,
					targetAudience: true,
					personas: [{ path: '$', }, true],
					created_by: true,
					created_at: true,
					updated_at: true,
				}
			]
		});

		return result.audiences as Array<ModelTypes["audiences"]>;
	}

	async getAudienceById(id: string): Promise<ModelTypes["audiences"] | undefined> {
		const result = await this.chain('query')({
			audiences_by_pk: [
				{ id: id },
				{
					id: true,
					brandId: true,
					brandName: true,
					segment: true,
					productOrService: true,
					targetAudience: true,
					created_by: true,
					created_at: true,
					updated_at: true,
					personas: [{ path: '$', }, true]
				}
			]
		});

		return result.audiences_by_pk as ModelTypes["audiences"] | undefined;
	}

	async getAudienceAggregate(): Promise<number> {
		const result = await this.chain('query')({
			audiences_aggregate: [
				{
					where: { deleted: { _eq: false } }
				},
				{
					aggregate: {
						count: [
							{ columns: [audiences_select_column.id] },
							true
						]
					}
				}
			]
		});

		return result.audiences_aggregate.aggregate.count as number;
	}

	async insertAudience({ ...command }: IInsertOrUpdateAudience): Promise<ModelTypes["audiences"] | undefined> {
		const result = await this.chain('mutation')({
			insert_audiences_one: [
				{
					object: {
						...command
					}
				},
				{
					id: true,
				}
			]
		});

		return result.insert_audiences_one as ModelTypes["audiences"];
	}

	async upsertAudience(command: IUpsertAudience): Promise<ModelTypes["UpsertAudienceOutput"] | undefined> {
		const result = await this.chain('mutation')({
			upsertAudience: [
				{ ...command },
				{
					id: true,
					personas: true,
				}
			]
		});

		return result.upsertAudience as ModelTypes["UpsertAudienceOutput"];
	}

	async updateAudienceById({ id, command }: { id: string, command: IInsertOrUpdateAudience }): Promise<ModelTypes["audiences"] | undefined> {
		const result = await this.chain('mutation')({
			update_audiences_by_pk: [
				{
					pk_columns: {
						id,
					},
					_set: command as ValueTypes["audiences_set_input"] | undefined | null
				},
				{
					id: true,
				}
			]
		});

		return result.update_audiences_by_pk as ModelTypes["audiences"];
	}

	async deleteAudienceById(id: string): Promise<ModelTypes["audiences"] | undefined> {
		const result = await this.chain('mutation')({
			update_audiences_by_pk: [
				{
					pk_columns: { id: id },
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_audiences_by_pk as ModelTypes["audiences"];
	}

	async getGeneratedPromptByTemplate(): Promise<Array<ModelTypes["GeneratedPromptByTemplate"]>> {
		const result = await this.chain('query')({
			GeneratedPromptByTemplate: [
				{},
				{
					id: true,
					result: true,
					created_at: true,
					created_by: true,
					alias: true,
					scheduledDate: true,
					channel: true,
					status: true,
					TextPromptTemplate: {
						name: true,
						creditPrice: true,
						saleCreditPrice: true,
					}
				}
			]
		});

		return result.GeneratedPromptByTemplate as Array<ModelTypes["GeneratedPromptByTemplate"]>;
	}

	async getGeneratedPromptByTemplatePaging(page: number = 1, pageSize: number = 10, where: {}): Promise<{ data: Array<ModelTypes["GeneratedPromptByTemplate"]>, total: number }> {
		const offset = (page - 1) * pageSize;

		const result = await this.chain('query')({
			GeneratedPromptByTemplate: [
				{
					order_by: [{ created_at: order_by.desc }],
					limit: pageSize,
					offset: offset,
					where: where
				},
				{
					id: true,
					result: true,
					created_at: true,
					created_by: true,
					alias: true,
					scheduledDate: true,
					channel: true,
					status: true,
					publishDate: true,
					TextPromptTemplate: {
						name: true,
						creditPrice: true,
						saleCreditPrice: true,
					}
				}
			],
			GeneratedPromptByTemplate_aggregate: [
				{
					where: where
				},
				{
					aggregate: {
						count: [{ columns: [GeneratedPromptByTemplate_select_column.id], distinct: true }, true]
					}
				}
			]
		});

		return { data: result.GeneratedPromptByTemplate, total: result.GeneratedPromptByTemplate_aggregate.aggregate.count } as { data: Array<ModelTypes["GeneratedPromptByTemplate"]>, total: number };
	}

	async getGeneratedPromptByTemplateAggregateProject(): Promise<{ draft: ModelTypes["GeneratedPromptByTemplate_aggregate"], scheduled: ModelTypes["GeneratedPromptByTemplate_aggregate"], posted: ModelTypes["GeneratedPromptByTemplate_aggregate"] }> {
		const result = await this.chain('query')({
			__alias: {
				draft: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { status: { _eq: "draft" } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.status] },
									true
								]
							}
						}
					]
				},
				scheduled: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { status: { _eq: "scheduled" } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.status] },
									true
								]
							}
						}
					]
				},
				posted: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { status: { _eq: "posted" } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.status] },
									true
								]
							}
						}
					]
				},
			}
		});

		return result as { draft: ModelTypes["GeneratedPromptByTemplate_aggregate"], scheduled: ModelTypes["GeneratedPromptByTemplate_aggregate"], posted: ModelTypes["GeneratedPromptByTemplate_aggregate"] };
	}

	async getGeneratedPromptByTemplateAggregateFunnel(): Promise<{ meioFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"], fundoFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"], topoFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"] }> {
		const result = await this.chain('query')({
			__alias: {
				meioFunil: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { TextPromptTemplate: { TextPromptTemplateCategory: { id: { _eq: "f81f22db-494a-4e5e-90f1-787651f5875a" } } } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.id] },
									true
								]
							}
						}
					]
				},
				fundoFunil: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { TextPromptTemplate: { TextPromptTemplateCategory: { id: { _eq: "1fb4a117-f9cd-4ba8-bf6c-5677cfc842c0" } } } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.id] },
									true
								]
							}
						}
					]
				},
				topoFunil: {
					GeneratedPromptByTemplate_aggregate: [
						{ where: { TextPromptTemplate: { TextPromptTemplateCategory: { id: { _eq: "b4077f22-1716-45ff-9f40-bfc94b50a2f2" } } } } },
						{
							aggregate: {
								count: [
									{ columns: [GeneratedPromptByTemplate_select_column.id] },
									true
								]
							}
						}
					]
				}
			}
		});

		return result as { meioFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"], fundoFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"], topoFunil: ModelTypes["GeneratedPromptByTemplate_aggregate"] };
	}

	async getGeneratedPromptByTemplateById(id: string): Promise<ModelTypes["GeneratedPromptByTemplate"] | undefined> {
		const result = await this.chain('query')({
			GeneratedPromptByTemplate_by_pk: [
				{ id: id },
				{
					id: true,
					alias: true,
					channel: true,
					created_at: true,
					created_by: true,
					result: true,
					scheduledDate: true,
					status: true,
					textPromptTemplateId: true,
					selectedDisplayFormatId: true,
					generatingDisplayStatus: true,
					displayImageUrl: true,
					displayMapper: [{ path: '$' }, true],
					command: [{ path: '$' }, true],
					displayFormat: {
						displayDimensionId: true,
					},
					TextPromptTemplate: {
						availableDisplays: [
							{},
							{
								displayFormatId: true,
								displayFormat: {
									id: true,
									mapper: [{ path: '$' }, true],
								}
							}
						]
					}
				}
			]
		});

		return result.GeneratedPromptByTemplate_by_pk as ModelTypes["GeneratedPromptByTemplate"];
	}

	async getGeneratedPromptByTemplateByIdSubscription(id: string): Promise<void> {

		const result = this.subscription('subscription')({
			GeneratedPromptByTemplate_by_pk: [
				{ id: id },
				{
					id: true,
					alias: true,
					channel: true,
					created_at: true,
					created_by: true,
					result: true,
					scheduledDate: true,
					status: true,
					textPromptTemplateId: true,
					selectedDisplayFormatId: true,
					generatingDisplayStatus: true,
					displayImageUrl: true,
					displayMapper: [{ path: '$' }, true],
					command: [{ path: '$' }, true],
					displayFormat: {
						displayDimensionId: true,
						newTemplate: true,
						bannerbearId: true,
					},
					TextPromptTemplate: {
						availableDisplays: [
							{},
							{
								displayFormatId: true,
								displayFormat: {
									id: true,
									mapper: [{ path: '$' }, true],
								}
							}
						]
					},
					GeneratedPromptByTemplateImages: [
						{},
						{
							id: true,
							fileId: true,
						}
					],
				}
			]
		});

		result.on(({ GeneratedPromptByTemplate_by_pk }: { GeneratedPromptByTemplate_by_pk: ModelTypes["GeneratedPromptByTemplate"] }) => {
			console.log('GeneratedPromptByTemplate_by_pk', GeneratedPromptByTemplate_by_pk);

			this.generatedPromptByTemplateSubscription = GeneratedPromptByTemplate_by_pk
		})
	}

	async insertGeneratedPromptByTemplateOne({ textPromptTemplateId, alias, channel }: { textPromptTemplateId: string, alias: string, channel: string }): Promise<ModelTypes["GeneratedPromptByTemplate"] | undefined> {
		const result = await this.chain('mutation')({
			insert_GeneratedPromptByTemplate_one: [
				{
					object: {
						textPromptTemplateId: textPromptTemplateId,
						alias: alias,
						channel: channel,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.insert_GeneratedPromptByTemplate_one as ModelTypes["GeneratedPromptByTemplate"];
	}

	async deleteGeneratedPromptByTemplateById(id: string): Promise<ModelTypes["GeneratedPromptByTemplate"] | undefined> {
		const result = await this.chain('mutation')({
			delete_GeneratedPromptByTemplate_by_pk: [
				{ id: id },
				{
					id: true,
					alias: true,
					channel: true,
					created_at: true,
					created_by: true,
					result: true,
					scheduledDate: true,
					status: true,
					textPromptTemplateId: true,
					command: [{ path: '$' }, true]
				}
			]
		});

		return result.delete_GeneratedPromptByTemplate_by_pk as ModelTypes["GeneratedPromptByTemplate"];
	}

	async deactivateGeneratedPromptByTemplateById(id: string): Promise<ModelTypes["GeneratedPromptByTemplate"]> {
		const result = await this.chain('mutation')({
			update_GeneratedPromptByTemplate_by_pk: [
				{
					pk_columns: { id: id },
					_set: { active: false }
				},
				{
					id: true,
				}
			]
		});

		return result.update_GeneratedPromptByTemplate_by_pk as ModelTypes["GeneratedPromptByTemplate"];
	}

	async getDisplayFormats(): Promise<Array<ModelTypes["displayFormats"]>> {
		const result = await this.chain('query')({
			displayFormats: [
				{},
				{
					id: true,
					name: true,
					bannerbearId: true,
					displayName: [{ path: '$', }, true],
					mapper: [{ path: '$', }, true],
					displayCategoryId: true,
				}
			]
		});

		return result.displayFormats as Array<ModelTypes["displayFormats"]>;
	}

	async getDisplayFormatsByDimension(dimensionId: string, requiresBrand: boolean): Promise<Array<ModelTypes["displayFormats"]>> {
		const result = await this.chain('query')({
			displayFormats: [
				{
					where: {
						displayDimensionId: { _eq: dimensionId },
						requiresBrand: { _eq: requiresBrand }
					}
				},
				{
					id: true,
					name: true,
					bannerbearId: true,
					displayDimension: {
						displayName: [{ path: '$', }, true],
						id: true,
					},
					displayName: [{ path: '$', }, true],
					mapper: [{ path: '$', }, true]
				}
			]
		});

		return result.displayFormats as Array<ModelTypes["displayFormats"]>;
	}

	async getAllDisplayFormatsByDimension(dimensionId: string): Promise<Array<ModelTypes["displayFormats"]>> {
		const result = await this.chain('query')({
			displayFormats: [
				{
					where: {
						displayDimensionId: { _eq: dimensionId },
					}
				},
				{
					id: true,
					name: true,
					bannerbearId: true,
					displayDimension: {
						displayName: [{ path: '$', }, true],
						id: true,
					},
					displayName: [{ path: '$', }, true],
					mapper: [{ path: '$', }, true]
				}
			]
		});

		return result.displayFormats as Array<ModelTypes["displayFormats"]>;
	}
	async getProducts(): Promise<Array<ModelTypes["products"]>> {
		const result = await this.chain('query')({
			products: [
				{},
				{
					id: true,
					alias: true,
					type: true,
					category: true,
					price: true,
					salePrice: true,
					linkConversion: true,
					created_by: true,
					created_at: true,
					updated_at: true,
				}
			]
		})

		return result.products as Array<ModelTypes["products"]>;
	}

	async getProductsPaging(page: number = 1, pageSize: number = 10): Promise<{ data: Array<ModelTypes["products"]>, total: number }> {
		const offset = (page - 1) * pageSize;

		const result = await this.chain('query')({
			products: [
				{
					limit: pageSize,
					offset: offset,
				},
				{
					id: true,
					alias: true,
					type: true,
					category: true,
					price: true,
					salePrice: true,
					linkConversion: true,
					created_by: true,
					created_at: true,
					updated_at: true,
				}
			],
			products_aggregate: [
				{},
				{
					aggregate: {
						count: [{ columns: [products_select_column.id], distinct: true }, true]
					}
				}
			]
		});

		return { data: result.products, total: result.products_aggregate.aggregate.count } as { data: Array<ModelTypes["products"]>, total: number };
	}

	async getProductById(id: string): Promise<ModelTypes["products"]> {
		const result = await this.chain('query')({
			products_by_pk: [
				{ id: id },
				{
					id: true,
					alias: true,
					type: true,
					category: true,
					linkConversion: true,
					price: true,
					salePrice: true,
					characteristics: [{ path: '$', }, true],
					benefits: [{ path: '$', }, true],
					wordKeys: [{ path: '$', }, true],
					testimonials: [{ path: '$', }, true],
					description: true,
					created_by: true,
					created_at: true,
					updated_at: true,
				}
			]
		});

		return result.products_by_pk as ModelTypes["products"];
	}

	async getProductAggregate(): Promise<number> {
		const result = await this.chain('query')({
			products_aggregate: [
				{
					where: { deleted: { _eq: false } }
				},
				{
					aggregate: {
						count: [
							{ columns: [products_select_column.id] },
							true
						]
					}
				}
			]
		});

		return result.products_aggregate.aggregate.count as number;
	}

	async insertProduct(command: Omit<IInsertOrUpdateProduct, 'id'>): Promise<ModelTypes["products"] | undefined> {
		const result = await this.chain('mutation')({
			insert_products_one: [
				{
					object: command as ValueTypes["products_insert_input"] | undefined | null
				},
				{
					id: true,
				}
			]
		});

		return result.insert_products_one as ModelTypes["products"];
	}

	async updateProductById(command: IInsertOrUpdateProduct): Promise<ModelTypes["products"] | undefined> {
		const { id, ...set } = command;

		const result = await this.chain('mutation')({
			update_products_by_pk: [
				{
					pk_columns: {
						id: id,
					},
					_set: set as ValueTypes["products_set_input"] | undefined | null
				},
				{
					id: true,
				}
			]
		});

		return result.update_products_by_pk as ModelTypes["products"];
	}

	async deleteProductById(id: string): Promise<ModelTypes["products"] | undefined> {
		const result = await this.chain('mutation')({
			update_products_by_pk: [
				{
					pk_columns: {
						id: id,
					},
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_products_by_pk as ModelTypes["products"];
	}

	async selectNewDisplay({ ...command }: ISelectNewDisplay): Promise<ModelTypes["SelectNewDisplayOutput"] | undefined> {
		if (!(typeof command.backgroundImageFileId === 'string' || typeof command.backgroundImageFileId === 'undefined')) {
			command.backgroundImageFileId = (command.backgroundImageFileId as any).id
		}
		const result = await this.chain('mutation')({
			selectNewDisplay: [
				{ ...command },
				{
					id: true,
					error: true,
				}
			]
		});

		return result.selectNewDisplay;
	}

	async getDisplayDimension(): Promise<Array<ModelTypes["displayDimension"]>> {
		const result = await this.chain('query')({
			displayDimension: [
				{},
				{
					id: true,
					name: true,
					displayName: [{ path: '$', }, true],
				}
			]
		});

		return result.displayDimension as Array<ModelTypes["displayDimension"]>;
	}

	async getChannels(): Promise<Array<ModelTypes["channels"]>> {
		const result = await this.chain('query')({
			channels: [
				{},
				{
					id: true,
					alias: true,
					displayName: true,
				}
			]
		});

		return result.channels as Array<ModelTypes["channels"]>;
	}

	async getUserCreditUsageSummary({ initialDate, finalDate }: { initialDate: string, finalDate: string }): Promise<Array<ModelTypes["userCreditUsageSummary"]>> {
		const result = await this.chain('query')({
			userCreditUsageSummary: [
				{
					where: { yearMonthDayReference: { _gte: initialDate, _lte: finalDate } }
				},
				{
					freeCreditUsage: true,
					totalCharged: true,
					yearMonthDayReference: true,
				}
			]
		});

		return result.userCreditUsageSummary as Array<ModelTypes["userCreditUsageSummary"]>;
	}

	async linkedInTokenCallback(code: string, brandId: string): Promise<ModelTypes["LinkedInTokenCallbackOutput"] | undefined> {
		const result = await this.chain('mutation')({
			linkedInTokenCallback: [
				{
					code,
					brandId
				},
				{
					success: true,
				}
			]
		});

		return result.linkedInTokenCallback;
	}

	async postToLinkedIn(command: IPostToLinkedIn): Promise<ModelTypes["PostToLinkedInOutput"] | undefined> {
		const result = await this.chain('mutation')({
			postToLinkedIn: [
				{ ...command },
				{ success: true }
			]
		});

		return result.postToLinkedIn as ModelTypes["PostToLinkedInOutput"];
	}

	async getFacebookToken(brandId: string): Promise<ModelTypes["GetFacebookTokenOutput"] | undefined> {
		const result = await this.chain('query')({
			getFacebookToken:
			{
				loginUrl: true,
			}

		});

		return result.getFacebookToken;
	}

	async facebookAuthCallback(code: string, brandId: string): Promise<ModelTypes["FacebookAuthCallbackOutput"] | undefined> {
		const result = await this.chain('mutation')({
			facebookAuthCallback: [
				{
					code,
					brandId
				},
				{
					success: true,
				}
			]
		});

		return result.facebookAuthCallback;
	}

	async getFacebookPages(integrationId: string): Promise<ModelTypes["GetFacebookPagesOutput"] | undefined> {
		const result = await this.chain('query')({
			getFacebookPages: [
				{ integrationId },
				{
					pages: {
						id: true,
						name: true,
					}
				}
			]
		});

		return result.getFacebookPages as ModelTypes["GetFacebookPagesOutput"];
	}

	async postToFacebook(command: IPostToFacebook): Promise<ModelTypes["PostToFacebookOutput"] | undefined> {
		const result = await this.chain('mutation')({
			postToFacebook: [
				{ ...command },
				{ success: true }
			]
		});

		return result.postToFacebook as ModelTypes["PostToFacebookOutput"];
	}

	async getInstagramToken(brandId: string): Promise<ModelTypes["GetInstagramTokenOutput"] | undefined> {
		const result = await this.chain('query')({
			getInstagramToken:

			{
				loginUrl: true,
			}

		});

		return result.getInstagramToken;
	}

	async instagramAuthCallback(code: string, brandId: string): Promise<ModelTypes["GetInstagramAuthCallbackOutput"] | undefined> {
		const result = await this.chain('mutation')({
			getInstagramAuthCallback: [
				{
					code,
					brandId
				},
				{
					success: true,
				}
			]
		});

		return result.getInstagramAuthCallback;
	}

	async getInstagramPages(integrationId: string): Promise<ModelTypes["GetInstagramPagesOutput"] | undefined> {
		const result = await this.chain('query')({
			getInstagramPages: [
				{ integrationId },
				{
					accounts: {
						id: true,
						name: true,
						instagramBusinessAccountId: true
					}
				}
			]
		});

		return result.getInstagramPages;
	}

	async postFeedToInstagram(command: IPostToInstagram): Promise<ModelTypes["PostFeedToInstagramOutput"] | undefined> {
		const result = await this.chain('mutation')({
			postFeedToInstagram: [
				{ ...command },
				{ success: true }
			]
		});

		return result.postFeedToInstagram as ModelTypes["PostFeedToInstagramOutput"];
	}

	async getFileById(id: string): Promise<ModelTypes["files"]> {
		const result = await this.chain('query')({
			file: [
				{ id: id },
				{
					id: true,
					bucketId: true,
				}
			]
		})

		return result.file as ModelTypes["files"];
	}

	async saveUserData(command: ModelTypes["userOnboarding_insert_input"]): Promise<ModelTypes["userOnboarding"] | undefined> {
		const result = await this.chain('mutation')({
			update_userOnboarding_by_pk: [
				{
					pk_columns: {
						userId: command.userId
					},
					_set: {
						userId: command.userId,
						name: command.name,
						phone: command.phone,
						brandName: command.brandName,
						siteUrl: command.siteUrl,
						companySegment: command.companySegment,
						staffCount: command.staffCount,
						expectation: command.expectation,
						productOrService: command.productOrService,
						marketingObjective: command.marketingObjective,
						importantSocialNetworkForBusiness: command.importantSocialNetworkForBusiness,
						sellOnCopyMagico: command.sellOnCopyMagico,
						networkingOnCopyMagico: command.networkingOnCopyMagico,
						investInPaidChannels: command.investInPaidChannels,
					}
				},
				{
					userId: true,
				}
			]
		})

		return result.update_userOnboarding_by_pk as ModelTypes["userOnboarding"] | undefined;
	}

	async updateUser(command: ModelTypes["users_insert_input"]): Promise<ModelTypes["users"]> {
		const result = await this.chain('mutation')({
			updateUser: [
				{
					pk_columns: { id: command.id },
					_set: {
						displayName: command.displayName,
						email: command.email,
						phoneNumber: command.phoneNumber,
						avatarUrl: command.avatarUrl
					}
				},
				{
					id: true,
					avatarUrl: true,
				}
			]
		});

		return result.updateUser as ModelTypes["users"];
	}

	async renderDisplay(template: string, data: any): Promise<ModelTypes['RenderDisplayOutput']> {
		const result = await this.chain('query')({
			renderDisplay: [
				{ template, data },
				{
					image: true,
				}
			]
		});

		return result.renderDisplay;
	}

	async renderAndSaveDisplayImage({ generatedPromptByTemplateId, template, data }: { generatedPromptByTemplateId: string, template: string, data: string }): Promise<ModelTypes["RenderAndSaveDisplayImageOutput"]> {
		const result = await this.chain('mutation')({
			renderAndSaveDisplayImage: [
				{ generatedPromptByTemplateId, template, data },
				{ image: true }
			]
		});

		return result.renderAndSaveDisplayImage as ModelTypes["RenderAndSaveDisplayImageOutput"];
	}
	async getPricingPlans(): Promise<Array<ModelTypes["pricingPlan"]>> {
		const result = await this.chain('query')({
			pricingPlan: [
				{
					order_by: [{ order: order_by.asc }]
				},
				{
					id: true,
					stripeProductId: true,
					stripePriceId: true,
					created_at: true,
					updated_at: true,
					alias: true,
					order: true,
					htmlFeatureItems: true,
					monthlyPrice: true,

				}
			]
		});

		return result.pricingPlan as Array<ModelTypes["pricingPlan"]>;
	}

	async updateGeneratedPromptByTemplateById({ id, result, displayMapper }: { id: string, result: string, displayMapper: string }): Promise<ModelTypes["GeneratedPromptByTemplate"] | undefined> {
		const response = await this.chain('mutation')({
			update_GeneratedPromptByTemplate_by_pk: [
				{
					pk_columns: {
						id
					},
					_set: {
						result,
						displayMapper,
					}
				},
				{
					id: true,
				}
			]
		})

		return response.update_GeneratedPromptByTemplate_by_pk as ModelTypes["GeneratedPromptByTemplate"];
	}

	async consumptionByChannelView(): Promise<Array<ModelTypes["consumptionByChannelView"]>> {
		const result = await this.chain('query')({
			consumptionByChannelView: [
				{},
				{
					channel: true,
					rascunhos: true,
					agendados: true,
					publicados: true,
					mercado: true,
					copymagico: true,
					economia: true,
				}
			]
		});

		return result.consumptionByChannelView;
	}

	async getConsumptionByChannelViewSubscription(): Promise<void> {
		const result = this.subscription('subscription')({
			consumptionByChannelView: [
				{},
				{
					channel: true,
					rascunhos: true,
					agendados: true,
					publicados: true,
					mercado: true,
					copymagico: true,
					economia: true,
				}
			]
		});

		result.error((error) => { console.log('consumptionByChannelView', error) });

		result.on(({ consumptionByChannelView }: { consumptionByChannelView: Array<ModelTypes["consumptionByChannelView"]> }) => {
			this.consumptionByChannelViewSubscription = consumptionByChannelView;
		});
	}

	async insertUserFeedback(command: ModelTypes['userFeedback_insert_input']): Promise<string> {
		const result = await this.chain('mutation')({
			insert_userFeedback_one: [
				{
					object: command
				},
				{
					id: true,
				}
			]
		});

		return result.insert_userFeedback_one.id as string;
	}

	async userMedia(): Promise<Array<ModelTypes["userMedia"]>> {
		const result = await this.chain('query')({
			userMedia: [
				{},
				{
					id: true,
					fileId: true,
					ownerId: true,
					created_at: true,
					updated_at: true,
				}
			]
		});

		return result?.userMedia as Array<ModelTypes["userMedia"]>
	}

	async publishOrScheduleProject(command: IPublishOrScheduleProject): Promise<ModelTypes["PublishOrScheduleProjectOutput"]> {
		const result = await this.chain('mutation')({
			publishOrScheduleProject: [
				{
					channel: command.channel,
					subtype: command.subtype,
					projectId: command.projectId,
					integrationId: command.integrationId,
					scheduledDate: command.scheduledDate,
					facebookPageId: command.facebookPageId,
					instagramBusinessId: command.instagramBusinessId,
					blogTags: command.blogTags,
					blogCategory: command.blogCategory,
				},
				{
					success: true,
					errorMessage: true,
				}
			]
		});

		return result?.publishOrScheduleProject as ModelTypes["PublishOrScheduleProjectOutput"]
	}

	async deleteProjectById(id: string): Promise<ModelTypes["project"] | undefined> {
		const result = await this.chain('mutation')({
			update_project_by_pk: [
				{
					pk_columns: {
						id: id,
					},
					_set: {
						deleted: true,
					}
				},
				{
					id: true,
				}
			]
		});

		return result.update_project_by_pk as ModelTypes["project"];
	}
}

export const IZeusClient = DI.createInterface<ZeusClient>(x => x.singleton(ZeusClient));