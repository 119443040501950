import { User } from '@nhost/nhost-js';
import { watch } from "@aurelia/runtime-html";
import { appRoutes } from 'common/routers/app-route';
import { IRouteableComponent, routes } from '@aurelia/router';
import { initCollapses, initDrawers, initDropdowns } from 'flowbite';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IDialogService } from '@aurelia/dialog';
import { Onboarding } from '@components/onboarding/onboarding';
import { ModelTypes } from '@zeus';

@routes(appRoutes)

export class MyApp implements IRouteableComponent {

  private isOpenDrawer: boolean = true;

  constructor(
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient,
    @IDialogService private dialogService: IDialogService,
  ) { zeusClient.userDetailsSubscription(); }

  attached() {
    initDrawers();
    initCollapses();
    initDropdowns();

    this.zeusClient.getUserSubscription();
  }
  @watch('zeusClient.userDetails')
  async handleUserDetailsSubscription(newValue: ModelTypes['userMetadata'], oldValue: ModelTypes['userMetadata']) {
    console.log('newValue', newValue)
    if (typeof newValue?.onboardCompleted === 'undefined') return;
    if (newValue?.onboardCompleted === false) {
      const { dialog } = await this.dialogService.open({
        component: () => Onboarding,
        model: {},
        lock: true,
        startingZIndex: 100,
      });
    }
  }

  async handleAccountSubscription(newValue: User, oldValue: User) {
    console.log('newValue', newValue)
    if (!newValue) return;
    this.zeusClient.restart()
    this.zeusClient.getUserSubscription()
  }
}
