import { watch } from "@aurelia/runtime-html";
import { IValidationRules, ValidateInstruction } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { newInstanceForScope } from '@aurelia/kernel';
import { IEventAggregator } from 'aurelia';

import { IStepOne, IStepTwo } from './interfaces/steps-interface'
import { yesOrNoData, socialNetworksData, marketingObjectiveData, expectationData, productOrServiceData, companySegmentData, staffCountData } from './../../data'
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IDialogCustomElementViewModel, IDialogController, DefaultDialogDom, IDialogDom } from "@aurelia/dialog";
import { ICustomElementController } from '@aurelia/runtime-html';

export class Onboarding implements IDialogCustomElementViewModel {
  // campaignObjective: string;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private stepOne: IStepOne = {
    name: '',
    brandName: '',
    siteUrl: '',
    companySegment: '',
    productOrService: '',
    staffCount: '',
  }

  private stepTwo: IStepTwo = {
    marketingObjective: '',
    expectation: '',
    sellOnCopyMagico: '',
    networkingOnCopyMagico: '',
    importantSocialNetworkForBusiness: '',
    investInPaidChannels: '',
  }

  constructor(
    @IZeusClient private zeusClient: ZeusClient,
    @newInstanceForScope(IValidationController) private validationController: IValidationController,
    @IDialogDom private dialogDom: DefaultDialogDom,
    @IValidationRules private validationRules: IValidationRules,
    @IEventAggregator readonly ea: IEventAggregator
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";

    validationRules
      .on(this.stepOne)
      .ensure('name')
      .required()
      .ensure('brandName')
      .required()
      .ensure('companySegment')
      .required()
      .ensure('productOrService')
      .required()
      .ensure('staffCount')
      .required()

    validationRules
      .on(this.stepTwo)
      .ensure('marketingObjective')
      .required()
      .ensure('expectation')
      .required()
      .ensure('sellOnCopyMagico')
      .required()
      .ensure('networkingOnCopyMagico')
      .required()
      .ensure('importantSocialNetworkForBusiness')
      .required()
      .ensure('investInPaidChannels')
      .required()
  }

  @watch('zeusClient.userDetails')
  userDetailsChange(newUserDetails, oldUserDetails) { }

  currentStepIndex: number = 0;

  private steps: any[] = [
    { index: 0, stepTitle: 'Seu Negócio' },
    { index: 1, stepTitle: 'Suas Expectativas' },
    { index: 2, stepTitle: 'Surpresa copymagico' },
  ]

  private yesOrNoData = yesOrNoData;
  private staffCountData = staffCountData;
  private productOrServiceData = productOrServiceData;
  private companySegmentData = companySegmentData;
  private socialNetworksData = socialNetworksData;
  private expectationData = expectationData;
  private marketingObjectiveData = marketingObjectiveData;

  async activate(model?: any) {
    await this.loadOnboardingData();
  }
  async saveStep(): Promise<void> {
    const result = await this.validationController.validate();

    if (this.currentStepIndex === 0) {
      if (result.valid) {

        const response = await this.zeusClient.saveUserOnboardingStepOne({
          name: this.stepOne.name,
          brandName: this.stepOne.brandName,
          siteUrl: this.stepOne.siteUrl,
          companySegment: this.stepOne.companySegment,
          productOrService: this.stepOne.productOrService,
          staffCount: this.stepOne.staffCount,
        });

        this.currentStepIndex = response.currentStep;
      }
    } else if (this.currentStepIndex === 1) {
      if (result.valid) {

        const response = await this.zeusClient.saveUserOnboardingStepTwo({
          marketingObjective: this.stepTwo.marketingObjective,
          expectation: this.stepTwo.expectation,
          sellOnCopyMagico: this.stepTwo.sellOnCopyMagico,
          networkingOnCopyMagico: this.stepTwo.networkingOnCopyMagico,
          importantSocialNetworkForBusiness: this.stepTwo.importantSocialNetworkForBusiness,
          investInPaidChannels: this.stepTwo.investInPaidChannels,
        });

        this.currentStepIndex = response.currentStep;
      }
    }
  }


  async loadOnboardingData() {
    const response = await this.zeusClient.getUserOnboarding();

    this.stepOne.name = response?.name;
    this.stepOne.brandName = response?.brandName;
    this.stepOne.siteUrl = response?.siteUrl;
    this.stepOne.companySegment = response?.companySegment;
    this.stepOne.productOrService = response?.productOrService;
    this.stepOne.staffCount = response?.staffCount;

    this.stepTwo.marketingObjective = response?.marketingObjective;
    this.stepTwo.expectation = response?.expectation;
    this.stepTwo.sellOnCopyMagico = response?.sellOnCopyMagico;
    this.stepTwo.networkingOnCopyMagico = response?.networkingOnCopyMagico;
    this.stepTwo.importantSocialNetworkForBusiness = response?.importantSocialNetworkForBusiness;
    this.stepTwo.investInPaidChannels = response?.investInPaidChannels;

    this.currentStepIndex = response?.currentStep || 0;
  }

  previousStep() {
    this.currentStepIndex--;
  }

  async finishOnBoard(): Promise<void> {
    await this.zeusClient.finishOnBoard();
    await this.$dialog.ok();
  }

  // async generateNextMonthCalendar() {
  //   await this.zeusClient.generateNextMonthCalendar(this.campaignObjective);
  // }
}