<import from='@components/quill/quill-component' />
<import from='@components/card-header/card-header-component' />
<import from="@components/spinner-button/spinner-button-component" />
<import from='@components/card-status/card-status-component' />

<import from='./../../components/publish/publish-text/publish-text-component' />

<section>

  <card-status-component>
    <div au-slot="status"></div>
    <div au-slot="actions" class="flex gap-2">
      <spinner-button-component label="Salvar" btn-class="btn-primary-small !px-10" trigger-function.bind="() => addProjectBlog()"></spinner-button-component>
      <button if.bind="textId" click.trigger="publishTextComponentRef.showModal(textId)" class="btn-primary-small !px-10">Publicar</button>
    </div>
  </card-status-component>

  <div>
    <div class="flex flex-col lg:flex-row gap-4 mb-6 mt-8">
      <div class="flex flex-col lg:w-[40%] h-fit card p-4 gap-4">

        <!-- begin::brand -->
        <div class="flex-1">
          <label for="brand" class="form-label">Marca</label>
          <select id="brand" value.bind="selectedBrandId" class="form-input">
            <option model.bind="null">Selecione</option>
            <option repeat.for="item of brands" model.bind="item.id">${item.brandName}</option>
          </select>
        </div>
        <!-- end::brand -->

        <template if.bind="selectedBrandId">

          <!-- begin::product -->
          <div class="flex-1">
            <label for="product" class="form-label">Produtos</label>
            <select id="product" value.bind="selectedProductId" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of products" model.bind="item.id">${item.alias}</option>
            </select>
          </div>
          <!-- end::product -->

          <!-- begin::audience -->
          <div class="flex-1">
            <label for="audience" class="form-label">Audiências</label>
            <select id="audience" value.bind="selectedAudienceId" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of audiences" model.bind="item.id">${item.brandName}</option>
            </select>
          </div>
          <!-- end::audience -->

          <!-- begin::prompt -->
          <div class="flex-1">
            <label t="imagine.prompt" for="prompt" class="form-label">Prompt</label>
            <textarea id="prompt" name="prompt" value.bind="prompt" autocomplete="off" class="form-input !h-20 hide-scrollbar resize-none"></textarea>
          </div>
          <!-- end::prompt -->
        </template>

        <div>
          <label for="blog-title" class="form-label">Título do Texto</label>
          <validation-container>
            <input type="text" id="blog-title" class="form-input" value.bind="blogTitle & validate" autocomplete="off" />
          </validation-container>
        </div>
        <div>
          <label for="format" class="form-label">Formato</label>
          <select id="format" class="form-input" value.bind="format">
            <option value.bind="null">Selecione</option>
            <option value="artigo-informativo">Artigo Informatvo</option>
            <option value="benefits-list">Lista de Benefícios</option>
            <option value="how-it-works">Como funciona</option>
            <option value="comercial-article">Artigo Comercial</option>
          </select>
        </div>
        <div>
          <label for="voice-tone" class="form-label">Tom de Voz</label>
          <select id="voice-tone" class="form-input" value.bind="voice-tone">
            <option value.bind="null">Selecione</option>
            <option value="formal">Formal</option>
            <option value="informal">Informal</option>
            <option value="tecnico">Técnico</option>
            <option value="infantil">Infantil</option>
          </select>
        </div>
        <div>
          <label for="text-style" class="form-label">Estilo de Texto</label>
          <select id="text-style" class="form-input" value.bind="text-style">
            <option value.bind="null">Selecione</option>
            <option value="narrativo">Narrativo</option>
            <option value="academico">Academico</option>
            <option value="vendedor">Vendedor</option>
            <option value="persuasivo">Persuasivo</option>
          </select>
        </div>
        <div>
          <label for="seo-optimized" class="form-label">Otimizado para SEO* (só pagante)</label>
          <select id="seo-optimized" class="form-input" value.bind="seo-optimized">
            <option value.bind="null">Selecione</option>
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </select>
        </div>
        <div>
          <label for="seo-optimization-main-keyword" class="form-label">Palavra-chave principal (só aparece no sim de cima)</label>
          <input type="text" id="seo-optimization-main-keyword" class="form-input" value.bind="seoOptimizationMainKeyword">
        </div>
      </div>

      <div class="lg:w-[60%] h-fit flex flex-col gap-3">
        <div class="card p-4 flex gap-3">
          <div class="flex-1"><label class="form-label" for=""># Palavras</label><input class="form-input" readonly type="text"></div>
          <div class="flex-1"><label class="form-label" for="">AI Checker</label><input class="form-input" readonly type="text"></div>
          <div class="flex-1"><label class="form-label" for="">Score Copy</label><input class="form-input" readonly type="text"></div>
        </div>
        <div class="card">
          <div class="p-4 flex flex-col">
            <!-- <input type="file" id="imageBannerBlog" class="hidden" change.trigger="filesChanged($event)" accept="image/*">
          <label for="imageBannerBlog" class="w-full"> 
            <img src.bind="base64Data ?? 'https://pixsector.com/cache/517d8be6/av5c8336583e291842624.png'" alt="" class="w-full h-64 object-contain object-center border">
          </label>
          <validation-container>
            <input type="text" value.bind="imageFile & validate" class="hidden" />
          </validation-container> -->
            <quill-component id="editor" content.bind="content" view-model.ref="quillRef" value.two-way="content"></quill-component>
          </div>
        </div>
      </div>
    </div>
  </div>

  <publish-text-component view-model.ref="publishTextComponentRef"></publish-text-component>
</section>