import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class AudiencesComponent {
  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  private async getAudienceAggregate() {
    const result = await this.zeusClient.getAudienceAggregate();

    return result;
  }
}