<import from="@components/loading/loading-component" />

<div class="card p-4 h-full">
  <div class="relative h-full">
    <h5 t="dashboardcards.brands" class="font-bold text-base text-gray-900 dark:text-white">Marcas Cadastradas</h5>
    <div class="h-12">
      <h6 t="dashboardcards.brandssubtitle" class="text-sm text-gray-700 dark:text-gray-400 mb-4 line-clamp-2">As marcas podem ser editadas no menu <span class="font-bold">Marcas</span></h6>
    </div>

    <template promise.bind="getBrandAggregate()">
      <template pending>
        <loading-component></loading-component>
      </template>

      <template then.from-view="data">
        <a if.bind="data == 0" href="/brand" class="btn-secondary absolute -translate-y-1/2 -translate-x-1/2 top-2/4 left-1/2 z-30 motion-safe:animate-pulse w-[250px]">Criar marca</a>

        <div class="${data == 0 ? 'blur-sm pointer-events-none' : ''} pt-2">
          <div>
            <h5 class="mb-0 font-bold dark:text-white">
              Marcas
              <span class="font-bold leading-normal text-sm text-lime-500"><i class="fa-solid fa-arrow-up text-xs"></i>${data}</span>
            </h5>
          </div>
        </div>
      </template>

      <template catch.from-view="error">
        <div class="flex flex-col justify-center items-center">
          <span class="text-sm font-semibold text-red-600 dark:text-red-600">Ocorreu um erro 🤕</span>
          <span class="text-sm font-medium italic text-red-600 dark:text-red-600 pt-2">${error}</span>
        </div>
      </template>
    </template>

  </div>
</div>