<import from='@components/card-title/card-title-component' />

<import from="./components/user-data/user-data-component" />
<import from="./components/user-subscription/user-subscription-component" />
<import from="./components/change-password/change-password-component" />
<!-- <import from="./components/user-credit-usage-summary/user-credit-usage-summary-component" /> -->



<section>
  <card-title-component title-id="minhaConta"></card-title-component>

  <!-- beggin::user-data -->
  <user-data-component></user-data-component>
  <!-- end::user-data -->

  <!-- beggin::change-password -->
  <change-password-component></change-password-component>
  <!-- end::change-password -->

  <!-- beggin::user-subscription -->
  <user-subscription-component></user-subscription-component>
  <!-- end::user-subscription -->

  <!-- beggin::user-credit-usage-summary -->
  <!-- <user-credit-usage-summary-component></user-credit-usage-summary-component> -->
  <!-- end::user-credit-usage-summary -->

</section>