// import { IFeedbackPluginOptions, IFeedbackPluginType } from 'gtm-plugins';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class FeedbackComponent {

  constructor(@IZeusClient private zeusClient: ZeusClient, @IAuthService private authService: IAuthService) { }

  // feedbackOptions: IFeedbackPluginOptions = {
  //   types: [
  //     { text: 'Feedback Geral', icon: '😁', id: 'general' },
  //     { text: 'Eu tenho uma ideia', icon: '💡', id: 'idea' },
  //     { text: 'Eu encontrei um problema', icon: '🐞', id: 'bug' },
  //   ],
  //   sucessTimeout: 2000,
  //   emptyMessageError: 'A mensagem não pode ser vazia!',
  //   color: 'white',
  //   btnTitle: 'Feedback', // título do botão
  //   title: 'Feedback da Empresa', // texto no topo
  //   inputPlaceholder: 'Seu feedback vai aqui!',
  //   submitText: 'Enviar', // texto para o botão de enviar
  //   backText: 'Voltar', // texto para o botão de voltar
  //   contactText: 'Ou envie um email!', // texto para a opção de outro contato
  //   contactLink: 'mailto:contato@copymagico.com.br', // link para a opção de outro contato
  //   typeMessage: 'Que tipo de feedback você tem?', // mensagem para selecionar o tipo de feedback
  //   success: 'Obrigado! 👊', // mensagem exibida após submissão bem-sucedida
  //   primaryColor: 'rgb(2, 132, 199)'
  // }

  // async feedbackSubmitFuction(message: string, selectedItem: IFeedbackPluginType) {
  //   console.log(this)
  //   await this.zeusClient.insertUserFeedback({
  //     message: message,
  //     feedbackType: selectedItem.id,
  //   })
  // }
}