import { bindable } from "aurelia";
import { cursor_ordering, order_by } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { GenericListComparsionType, IGenericListConfig } from './interfaces/generic-filtered-interface';

type ColumnConfig = {
  path?: string;
};

type Structure = {
  [key: string]: Structure | boolean | Array<Structure> | string | [ColumnConfig, boolean];
};

export class GenericListComponent {
  @bindable config: IGenericListConfig;

  private totalPages: number = 0;
  private visiblePages: number[] = [];

  private isLoading: boolean = false;

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  async attached() {
    await this.loadData(this.config);

    this.streamData();
  }

  private filterChanged() {
    const fixedFilters = this.config.fixedFilters?.filter(x => x.value).reduce((acc, filter) => {

      const fieldParts = filter.field.split('.'); // Dividir o campo por pontos

      switch (filter.comparsionType) {
        case GenericListComparsionType.Equals:
          if (fieldParts.length > 1) {
            acc[fieldParts[0]] = { ...acc[fieldParts[0]], [fieldParts[1]]: { _eq: filter.value } };
          } else {
            acc[filter.field] = { _eq: filter.value };
          }

          break;
        case GenericListComparsionType.In:
          acc[filter.field] = { ...filter.value.length > 0 ? { _in: filter.value } : {} };
          break;
        case GenericListComparsionType.GreaterThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _gt: filter.value };
          break;
        case GenericListComparsionType.LessThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lt: filter.value };
          break;
        case GenericListComparsionType.GreaterThanEquals:

          acc[filter.field] = { ...(acc[filter.field] || {}), _gte: filter.value };
          break;
        case GenericListComparsionType.LessThanEquals:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lte: filter.value };
          break;
        case GenericListComparsionType.Like:
          acc[filter.field] = { _like: `%${filter.value}%` };
          break;
        case GenericListComparsionType.ILike:
          acc[filter.field] = { _ilike: `%${filter.value}%` };
          break;
        case GenericListComparsionType.Between:
          const [min, max] = filter.between;

          acc[filter.field] = { ... (acc[filter.field] || {}), ...(min?.value !== null && typeof min?.value !== undefined && min?.value !== '') && { _gte: min.value }, ...(max?.value !== null && typeof max?.value !== undefined && max?.value !== '') && { _lte: max.value } };
          break;
        default:
          break;
      }

      return acc;
    }, {});

    const filters = this.config.filters.filter(x => x.value).reduce((acc, filter) => {

      switch (filter.comparsionType) {
        case GenericListComparsionType.Equals:
          acc[filter.field] = { _eq: filter.value };
          break;
        case GenericListComparsionType.In:
          acc[filter.field] = { ...filter.value.length > 0 ? { _in: filter.value } : {} };
          break;
        case GenericListComparsionType.GreaterThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _gt: filter.value };
          break;
        case GenericListComparsionType.LessThan:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lt: filter.value };
          break;
        case GenericListComparsionType.GreaterThanEquals:

          acc[filter.field] = { ...(acc[filter.field] || {}), _gte: filter.value };
          break;
        case GenericListComparsionType.LessThanEquals:
          acc[filter.field] = { ...(acc[filter.field] || {}), _lte: filter.value };
          break;
        case GenericListComparsionType.Like:
          acc[filter.field] = { _like: `%${filter.value}%` };
          break;
        case GenericListComparsionType.ILike:
          acc[filter.field] = { _ilike: `%${filter.value}%` };
          break;
        case GenericListComparsionType.Between:
          const [min, max] = filter.between;

          acc[filter.field] = { ... (acc[filter.field] || {}), ...(min?.value !== null && typeof min?.value !== undefined && min?.value !== '') && { _gte: min.value }, ...(max?.value !== null && typeof max?.value !== undefined && max?.value !== '') && { _lte: max.value } };
          break;
        default:
          break;
      }

      return acc;
    }, {});

    const response = { ...fixedFilters, ...filters };

    return response;
  }

  private orderByChanged() {
    const response = this.config.orderBy?.filter(x => x.value !== null).reduce((acc, order) => {
      // acc[order.field] = order.value ? order_by.asc : order_by.desc;
      acc[order.field] = order.value;

      return acc;
    }, {});

    return response;
  }

  async loadData(config: IGenericListConfig): Promise<any> {
    this.isLoading = true;

    const generateOrderBy = this.orderByChanged();
    const generateWhereFilter = this.filterChanged();

    const result: any = await this.zeusClient.chain('query')({
      [config.tableName]: [
        {
          // where: generateWhereFilter,
          where: {
            ...generateWhereFilter,
            deleted: { _eq: false }
          },
          limit: config.perPage,
          offset: (config.currentPage - 1) * config.perPage,
          order_by: generateOrderBy ?? { created_at: order_by.desc }
        },
        {
          ...this.generateColumnConfig(config.columns),
        },
      ],
      [this.config.aggregateName]: [
        {
          where: {
            ...generateWhereFilter,
            deleted: { _eq: false }
          },
        },
        {
          aggregate: {
            count: [{ columns: ['id'], distinct: true }, true]
          }
        }
      ]
    });


    config.data = result[config.tableName];
    config.total = result[config.aggregateName].aggregate.count;

    this.getTotalPages();
    this.getVisiblePages();

    this.isLoading = false;
  }

  private generateColumnConfig(columns: string[]): Structure {
    const result: Structure = {};

    for (const column of columns) {
      const parts = column.split('.');
      const nestedStructure = this.digestNestedStructure(parts, 0);
      this.mergeStructures(result, nestedStructure as Structure);
    }

    return result;
  }

  private digestNestedStructure(parts: string[], index: number): Structure | boolean {
    if (index >= parts.length) return true;

    const part = parts[index];
    const isArray = part.endsWith('[]');
    const key = isArray ? part.slice(0, -2) : part;

    if (isArray) {
      return {
        [key]: [
          {},
          this.digestNestedStructure(parts, index + 1)
        ]
      } as Structure;
    } else if (key === '$') {
      // Se a primeira parte é '$', tratamos como uma referência direta
      return { path: '$' + parts.slice(1).join('.') };
    } else {
      const remainingParts = parts.slice(index + 1);
      if (remainingParts.length > 0 && remainingParts[0] === '$') {
        return {
          [key]: [{ path: '$' + remainingParts.slice(1).join('.') }, true]
        };
      } else {
        return {
          [key]: this.digestNestedStructure(parts, index + 1)
        };
      }
    }
  }

  private mergeStructures(target: Structure, source: Structure): void {
    for (const key in source) {
      if (Array.isArray(source[key]) && Array.isArray(target[key])) {
        this.mergeStructures(target[key][1], source[key][1]);
      } else if (typeof source[key] === 'object' && typeof target[key] === 'object') {
        this.mergeStructures(target[key] as Structure, source[key] as Structure);
      } else {
        target[key] = source[key];
      }
    }
  }

  private async handleFilter(): Promise<void> {
    this.config.currentPage = 1;

    await this.loadData(this.config);
  }

  private async handleOrderBy(): Promise<void> {
    await this.loadData(this.config);
  }

  private getTotalPages(): void {
    const totalPages = Math.ceil(this.config.total / this.config.perPage);

    this.totalPages = totalPages;
  }

  private getVisiblePages(): void {
    const totalPages = this.totalPages;
    const maxVisible = 5; // Define quantas páginas visíveis deseja exibir

    let startPage = Math.max(1, this.config.currentPage - Math.floor(maxVisible / 2));
    let endPage = Math.min(totalPages, startPage + maxVisible - 1);

    if (endPage - startPage + 1 < maxVisible) {
      startPage = Math.max(1, endPage - maxVisible + 1);
    }

    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    this.visiblePages = visiblePages;
  }

  private goToPage(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return;
    }

    if (page !== this.config.currentPage) {
      this.config.currentPage = page;
      this.getVisiblePages();

      this.loadData(this.config);
    }
  }

  private streamData(): void {
    const stream = this.zeusClient.subscription('subscription')({
      [this.config.streamName]: [
        {
          batch_size: 1,
          cursor: [
            { initial_value: { updated_at: new Date() }, ordering: cursor_ordering.ASC }
          ],
        },
        {
          ...this.generateColumnConfig(this.config.columns),
        },
      ],
    });

    stream.error((error: any) => { console.log('Error', error) });

    stream.on(({ [this.config.streamName]: stream }: { [key: string]: any }) => {
      this.handleRealTimeKanbanData(stream);
    });
  }

  private handleRealTimeKanbanData(data: any) {
    const newData = data[0];

    this.config.data = this.config.data.map((item: any) => {
      if (item.id === newData.id) {
        return newData;
      }

      return item;
    });

    if (!this.config.data.find((item: any) => item.id === newData.id)) {
      this.config.data.push(newData);
    }

    if (newData.deleted) {
      this.config.data = this.config.data.filter((item: any) => item.id !== newData.id);
    }

  };

}