<import from='@components/card-header/card-header-component' />
<import from='@components/file-upload/file-upload-component' />
<import from="@components/spinner-button/spinner-button-component" />

<import from="./../user-avatar/user-avatar-component" />

<div class="flex flex-row gap-4 mb-6 mt-8">
  <!-- beggin:inputs -->
  <div class="card w-full h-fit">
    <card-header-component header-id="myAccountForm"></card-header-component>

    <form class="p-4" novalidate="novalidate">
      <!-- beggin::step 01 -->
      <div class="flex flex-col gap-4 mb-6">

        <div class="flex md:flex-row flex-col flex-auto gap-4">
          <div class="mr-2">
            <validation-container>
              <user-avatar-component id="avatar" label="Avatar" current-file.bind="avatarFile & validate" current-file-id.bind="avatarFileId"></user-avatar-component>
            </validation-container>
          </div>

          <div class="flex flex-col flex-[2_2_0%] gap-4">
            <div class="flex md:flex-row flex-col flex-auto gap-4">
              <!-- beggin::name -->
              <div class="flex-1">
                <label for="name" class="form-label">Seu nome</label>
                <validation-container>
                  <input type="text" name="name" id="name" autocomplete="off" value.bind="name & validate" class="form-input">
                </validation-container>
              </div>
              <!-- end::name -->

              <!-- beggin::email -->
              <div class="flex-1">
                <label for="email" class="form-label">E-mail</label>
                <input type="text" name="email" id="email" autocomplete="off" value.bind="email" class="form-input" disabled>
              </div>
              <!-- end::email -->

              <!-- beggin::phone -->
              <div class="flex-1">
                <label for="phone" class="form-label">Telefone</label>
                <validation-container>
                  <input type="text" name="phone" id="phone" autocomplete="off" value.bind="phone & validate" class="form-input" input-mask="phone">
                </validation-container>
              </div>
              <!-- end::phone -->
            </div>

            <div class="flex md:flex-row flex-col flex-auto gap-4">
              <!-- beggin::brandName -->
              <div class="flex-1">
                <label for="brandName" class="form-label">Nome da marca</label>
                <validation-container>
                  <input type="text" name="brandName" id="brandName" autocomplete="off" value.bind="brandName & validate" class="form-input">
                </validation-container>
              </div>
              <!-- end::brandName -->

              <!-- beggin::siteUrl -->
              <div class="flex-1">
                <label for="siteUrl" class="form-label">Site da sua empresa</label>
                <validation-container>
                  <input type="text" siteUrl="siteUrl" id="siteUrl" autocomplete="off" value.bind="siteUrl & validate" class="form-input">
                </validation-container>
              </div>
              <!-- end::siteUrl -->
            </div>
          </div>
        </div>

        <div class="flex md:flex-row flex-col flex-auto md:items-end gap-4">
          <!-- beggin::companySegment -->
          <div class="flex-1">
            <label for="companySegment" class="form-label">Qual segmento representa melhor a sua atividade?</label>
            <validation-container>
              <select id="companySegment" value.bind="companySegment & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of companySegmentData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <!-- end::companySegment -->

          <!-- beggin::staffCount -->
          <div class="flex-1">
            <label for="staffCount" class="form-label">Número de funcionários</label>
            <validation-container>
              <select id="staffCount" value.bind="staffCount & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of staffCountData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <!-- end::staffCount -->

          <!-- beggin::expectation -->
          <div class="flex-1">
            <label for="expectation" class="form-label">Qual é a sua principal expectativa com a Copymagico?</label>
            <validation-container>
              <select id="expectation" value.bind="expectation & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of expectationData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <!-- end::expectation -->
        </div>

        <div class="flex md:flex-row flex-col flex-auto md:items-end gap-4">
          <!-- beggin::productOrService -->
          <div class="flex-1">
            <label for="productOrService" class="form-label">Como você definiria o seu produto final ou modelo de negócio?</label>
            <validation-container>
              <select id="productOrService" value.bind="productOrService & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of productOrServiceData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <!-- end::productOrService -->

          <!-- beggin::marketingObjective -->
          <div class="flex-1">
            <label for="marketingObjective" class="form-label">Qual destes se aproxima do seu objetivo de marketing?</label>
            <validation-container>
              <select id="marketingObjective" value.bind="marketingObjective & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of marketingObjectiveData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <!-- end::marketingObjective -->

          <!-- beggin::importantSocialNetworkForBusiness -->
          <div class="flex-1">
            <label for="importantSocialNetworkForBusiness" class="form-label">Qual é a rede social mais importante para o seu negócio?</label>
            <validation-container>
              <select id="importantSocialNetworkForBusiness" value.bind="importantSocialNetworkForBusiness & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of socialNetworksData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <!-- end::importantSocialNetworkForBusiness -->
        </div>

        <div class="flex md:flex-row flex-col flex-auto md:items-end gap-4">
          <!-- beggin::sellOnCopyMagico -->
          <div class="flex-1">
            <label for="sellOnCopyMagico" class="form-label">Gostaria de oferecer o seu produto/serviço para outros usuários e empresas da Copymagico?</label>
            <validation-container>
              <select id="sellOnCopyMagico" value.bind="sellOnCopyMagico & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of yesOrNoData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <!-- end::sellOnCopyMagico -->

          <!-- beggin::networkingOnCopyMagico -->
          <div class="flex-1">
            <label for="networkingOnCopyMagico" class="form-label">Gostaria de fazer networking com especialistas, empresas e outros usuários da Copymagico?</label>
            <validation-container>
              <select id="networkingOnCopyMagico" value.bind="networkingOnCopyMagico & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of yesOrNoData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <!-- end::networkingOnCopyMagico -->

          <!-- beggin::investInPaidChannels -->
          <div class="flex-1">
            <label for="investInPaidChannels" class="form-label">Você investe em canais pagos como Google ou Meta Ads?</label>
            <validation-container>
              <select id="investInPaidChannels" value.bind="investInPaidChannels & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of yesOrNoData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>
          <!-- end::investInPaidChannels -->
        </div>

      </div>
      <!-- end::step 01 -->

      <!-- beggin::button -->
      <div class="flex justify-end">
        <button type="button" click.trigger="handleUpdateUserData()" class="btn-primary">Salvar conta</button>
      </div>
      <!-- end::button -->

    </form>
  </div>
  <!-- end:inputs -->
</div>