import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface ICalendarService {
  projects: Array<ModelTypes["project"]>;

  projectsSubscription(startDate: string, endDate: string): void;
  getProjects(startDate: string, endDate: string): Promise<Array<ModelTypes["project"]>>;
}

export class CalendarService implements ICalendarService {

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  public projects: Array<ModelTypes["project"]>;

  public projectsSubscription(startDate: string, endDate: string): void {
    const result = this.zeusClient.subscription('subscription')({
      project: [
        {
          where: {
            _and:
              [
                {
                  created_at: {
                    _gte: startDate
                  }
                },
                {
                  created_at: {
                    _lte: endDate
                  }
                },
                {
                  deleted: {
                    _eq: false
                  }
                }
              ],
          },
        },
        {
          id: true,
          alias: true,
          status: true,
          type: true,
          caption: true,
          created_at: true,
        },
      ]
    });

    result.error((error) => { console.log(error) });

    result.on(({ project }: { project: Array<ModelTypes["project"]> }) => {
      this.projects = project;
    })
  }
  async getProjects(startDate: string, endDate: string): Promise<Array<ModelTypes["project"]>> {
    const result = await this.zeusClient.chain('query')({
      project: [
        {
          where: {
            _and:
              [
                {
                  created_at: {
                    _gte: startDate
                  }
                },
                {
                  created_at: {
                    _lte: endDate
                  }
                },
                {
                  deleted: {
                    _eq: false
                  }
                }
              ],
          },
        },
        {
          id: true,
          alias: true,
          status: true,
          type: true,
          caption: true,
          created_at: true,
        },
      ]
    });

    return result.project as Array<ModelTypes["project"]>;
  }

}

export const ICalendarService = DI.createInterface<ICalendarService>(x => x.singleton(CalendarService));