<div class="flex flex-col lg:flex-row h-full w-full max-w-full shrink-0">
  <!-- begin::card -->
  <div class="card p-4">
    <div class="relative h-full overflow-hidden bg-cover rounded-xl bg-dash-courses">
      <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-gray-900 to-slate-800 opacity-80"></span>
      <div class="relative z-10 flex flex-col flex-auto h-full p-4">
        <h5 t="banner.coursecomponenttitle" class="pt-2 mb-4 font-bold text-white text-lg">Nossos cursos</h5>
        <p t="banner.coursecomponensubttitle" class="text-white leading-6 font-normal text-sm mb-4">A criação de riqueza
          é um jogo de soma positiva evolutivamente recente. É tudo sobre quem aproveita a oportunidade primeiro.</p>
        <!-- <a href="/courses" class="mt-auto mb-0 font-semibold leading-normal text-white group text-sm">
          Leia mais
          <i class="fas fa-arrow-right ease-bounce text-sm group-hover:translate-x-1.5 ml-1 leading-normal transition-all duration-200"></i>
        </a> -->
      </div>
    </div>
  </div>
  <!-- end::card -->
</div>