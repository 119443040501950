<import from='@components/card-status/card-status-component' />
<import from="@components/spinner-button/spinner-button-component" />

<import from='./../../components/publish/publish-social-media/publish-social-media-component' />

<section class="polotno-section">

  <card-status-component>
    <div au-slot="status"></div>
    <div au-slot="actions" class="flex gap-2">
      <div class="relative grid place-content-center btn-danger-small !px-10">
        <input type="file" accept="application/json" class="opacity-0 absolute inset-0 w-full h-full cursor-pointer" change.trigger="handleSelectedTemplate($event)"></input>

        <div class='flex items-center justify-center'>
          <span>Carregar template</span>
        </div>
      </div>
      <button if.bind="id" click.trigger="openSaveTemplateDialog()" class="btn-danger-small !px-10">Salvar template</button>

      <spinner-button-component label="Salvar" btn-class="btn-primary-small !px-10" trigger-function.bind="() => handleSave()"></spinner-button-component>
      <button if.bind="id" click.trigger="publishSocialMediaComponentRef.showModal(id)" class="btn-primary-small !px-10">Publicar</button>
    </div>
  </card-status-component>

  <div id="polotno" class="bp5-dark p-0">
    <div id="container" class="w-full h-[77vh]"></div>
  </div>

  <publish-social-media-component view-model.ref="publishSocialMediaComponentRef"></publish-social-media-component>
</section>