import { Modal } from 'flowbite'
import { ModelTypes } from '@zeus';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import type { ModalOptions, ModalInterface } from 'flowbite'
import { IValidationController } from '@aurelia/validation-html';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class AddIntegrationModalComponent {

  private modal: ModalInterface;

  private brandId: string;

  private wordpressUrl: string;
  private state: 'selecting' | 'configuring' = 'selecting';

  constructor(@IZeusClient private zeusClient: ZeusClient,
    @newInstanceForScope(IValidationController) private validationController: IValidationController,
    @IValidationRules private validationRules: IValidationRules) {
    validationRules
      .on(this)
      .ensure('wordpressUrl')
      .required()
      .ensure('wordpressUrl')
      .matches(/^(?!http|https)([a-zA-Z0-9]+\.)*[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[a-zA-Z]{2,63}$/)
      .withMessage('O link deve ser válido')
  }

  attached() {
    const $modalElement: HTMLElement = document.querySelector('#add-integration-modal');

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      closable: true,
    }

    this.modal = new Modal($modalElement, modalOptions);
  }

  public configureIntegrationCallback(e: string) {
    if (e === 'instagram') {
      this.instagramConfig();
      return;
    }

    if (e === 'linkedin') {
      this.linkedInConfig();
      return;
    }

    if (e === 'facebook') {
      this.facebookConfig();
      return;
    }

    this.state = 'configuring';
  }

  async getAppIntegrations(): Promise<Array<ModelTypes["AppIntegration"]>> {
    const integrations = await this.zeusClient.getAppIntegrations();
    return integrations;
  }

  cancelOrBack() {
    if (this.state === 'selecting') {
      this.modal.hide();
    } else {
      this.state = 'selecting';
    }
  }

  next() {
    this.state = 'configuring';
    const redirectUrl = 'https://' + this.wordpressUrl + `/wp-admin/authorize-application.php?app_name=CopyMagico&success_url=${window.location.origin}/wordpress-success?brandId=${this.brandId}`;
    window.location.href = redirectUrl;
  }

  async instagramConfig() {
    const { loginUrl } = await this.zeusClient.getInstagramToken(this.brandId);

    window.document.location.href = loginUrl;
  }

  async facebookConfig() {
    const { loginUrl } = await this.zeusClient.getFacebookToken(this.brandId);

    window.document.location.href = loginUrl;
  }

  async linkedInConfig() {
    const CLIENT_ID = '77k8v77rx23x9x'
    const URL_REDIRECT = 'https://app.copymagico.com/linkedin-success'

    const csrfState = this.brandId;

    let url = 'https://www.linkedin.com/oauth/v2/authorization/'

    url += `?client_id=${CLIENT_ID}`
    url += '&scope=r_liteprofile%20r_emailaddress%20w_member_social'
    url += '&response_type=code'
    url += `&redirect_uri=${URL_REDIRECT}`
    url += `&state=${csrfState}`

    window.document.location.href = url;
  }

  toQueryString(obj: { [x: string]: string | number | boolean; response_type?: string; client_id?: string; redirect_uri?: string; state?: string; scope?: string; hasOwnProperty?: any; }) {
    const parts = [];
    for (const i in obj) {
      if (obj.hasOwnProperty(i)) {
        parts.push(`${encodeURIComponent(i)}=${encodeURIComponent(obj[i])}`);
      }
    }
    return parts.join('&');
  }

  public openAddIntegrationModal(brandId: string): void {
    this.brandId = brandId;

    this.modal.show();
  }

  private hideModal(): void {
    this.modal.hide();
  }

}
