import { I18N } from '@aurelia/i18n';
import { Navigation, Parameters, RoutingInstruction } from '@aurelia/router';
import { ToastType } from '@components/toast/events/toast-event';
import { ToastComponent } from '@components/toast/toast-component';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { ModelTypes } from '@zeus';
import { IEventAggregator } from 'aurelia';
import { nhost } from 'lib/nhost';
import { IAtrStyleData } from './components/art-style/data';
import { IAspectRatioData } from './components/aspect-ratio/data';
import { IModelsData } from './components/models/data';
import { IImagineService } from './services/imagine-service';

export class ImagineModule {
  private ilustration: string = null;

  private brandId: string = null;
  private productId: string = null;
  private audienceId: string = null;

  private prompt: string;
  private selectedBrandId: string;
  private selectedProductId: string;
  private selectedAudienceId: string;

  private seed: number = null;
  private cfgScale: number = 3;
  private stepScale: number = 30;
  private negativePrompt: string = null;
  private selectedModel: IModelsData = null;
  private selectedAtrStyle: IAtrStyleData = null;
  private selectedAspectRatio: IAspectRatioData = null;

  private brands: ModelTypes['brands'][];
  private products: ModelTypes['products'][];
  private audiences: ModelTypes['audiences'][];

  constructor(
    @I18N private I18N: I18N,
    @IAuthService private auth: IAuthService,
    @IEventAggregator readonly ea: IEventAggregator,
    @IImagineService private service: IImagineService
  ) { }

  attached() {
    const token = this.auth.getAccessToken();

    nhost.storage.setAccessToken(token);

    this.handleGetBrands();

    this.selectedBrandId = this.brandId;
    this.selectedProductId = this.productId;
    this.selectedAudienceId = this.audienceId;
  }

  private async handleGetBrands(): Promise<void> {
    this.brands = await this.service.brands();
    this.products = await this.service.products();
    this.audiences = await this.service.audiences();
  }

  private async handleGenerate(): Promise<void> {
    const { fileId, success, errorMessage } = await this.service.imagine({
      prompt: this.prompt,
      aspectRatio: this.selectedAspectRatio.value,
      style: this.selectedModel.value,
    });

    if (!success) {
      this.showMessage({ title: 'Erro', message: 'Erro ao tentar gerar ilustração', type: ToastType.DANGER });
      return;
    }

    const { presignedUrl, error } = await nhost.storage.getPresignedUrl({ fileId })

    if (error) {
      this.showMessage({ title: 'Erro', message: 'Não foi possivel assinar ilustração', type: ToastType.DANGER });
      return;
    }

    this.showMessage({ title: 'Sucesso', message: 'Ilustração gerada com sucesso', type: ToastType.SUCCESS });

    this.ilustration = presignedUrl.url;
  }

  private showMessage({ title, message, type }: { title: string, message: string, type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type
      }
    });
  }
}