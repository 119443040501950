<import from='@components/card-title/card-title-component' />
<import from="@components/accordion/accordion-component" />


<section>

  <card-title-component title-id="faq"></card-title-component>

  <div class="flex flex-col lg:flex-row w-full gap-4 mt-10 mb-16">
    <div class="w-full xl:w-[40%] 2xl:w-[25%] overflow-x-auto lg:overflow-hidden card p-4">

      <ul class="flex flex-row lg:flex-col text-sm font-medium mb-4">
        <template repeat.for="item of faqs">
          <li click.trigger="changeCategory($index)" class="mr-2 shrink-0">
            <span class="flex w-full px-4 py-2 gap-4 cursor-pointer rounded-md ${$index === currentCategoryIndex ? 'text-white bg-secondary-600 font-bold' : 'hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white text-gray-500 dark:text-gray-400'}">
              <i class="${item.icon}"></i>
              ${item.name}
            </span>
          </li>
        </template>
      </ul>
      <div class="hidden lg:flex justify-center items-center mt-8">
        <img src="assets/images/faq/undraw_questions.svg" alt="faq_Illustration" class="h-48">
      </div>
    </div>

    <div class="w-full xl:w-[60%] 2xl:w-[75%]">
      <div class="flex flex-col gap-4">

        <template repeat.for="item of faqs[currentCategoryIndex].questions">
          <accordion-component title.bind="item.question">
            <div au-slot="body">
              <span class="text-xs text-[#808488]">${item.answer}</span>
            </div>
          </accordion-component>
        </template>

      </div>
    </div>

  </div>


</section>