<import from="@converters/currency-format-converter" />
<import from='@components/promise-bind/pending/pending-component' />

<div class="w-full max-w-full shrink-0">
  <!-- begin::card -->
  <div class="card">
    <div class="relative z-10 flex flex-col flex-auto h-full max-w-full overflow-hidden rounded-xl p-4">

      <div class="flex flex-col md:flex-row md:items-center justify-start md:justify-between">
        <div>
          <h5 t="dashboardcards.categories" class="font-bold text-base text-gray-900 dark:text-white">Gerencie todas as
            suas publicações</h5>
          <h6 t="dashboardcards.categoriessubtitle" class="text-sm font-semibold text-gray-400 dark:text-gray-400 mb-4">
            Todas os projetos podem ser visualizados no menu Projetos</h6>
        </div>

        <!-- beggin::legenda -->
        <div class="flex gap-3 items-center justify-end md:justify-center dark:text-gray-400 mb-5">
          <div class="h-2.5 w-2.5 rounded-sm bg-red-500 -mr-1"></div> <span
            class="leading-tight text-xs">${item.drafts}<span class="font-semibold">Rascunhos</span></span>
          <div class="h-2.5 w-2.5 rounded-sm bg-yellow-300 -mr-1"></div> <span
            class="leading-tight text-xs">${item.agendados} <span class="font-semibold">Agendados</span></span>
          <div class="h-2.5 w-2.5 rounded-sm bg-green-500 -mr-1"></div> <span
            class="leading-tight text-xs">${item.publicados} <span class="font-semibold">Publicados</span></span>
        </div>
        <!-- end::legenda -->
      </div>

      <div class="relative overflow-x-auto card">
        <table id="dash-category" class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-[11px] text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-3 py-3">Fonte</th>
              <th scope="col" class="px-3 py-3">Rascunhos</th>
              <th scope="col" class="px-3 py-3">Agendados</th>
              <th scope="col" class="px-3 py-3">Publicados</th>
              <th scope="col" class="px-3 py-3">Mercado</th>
              <th scope="col" class="px-3 py-3">Copymagico</th>
              <th scope="col" class="px-3 py-3">Economia</th>
            </tr>
          </thead>
          <tbody>
            <template repeat.for="item of consumptionByChannelViewSubscription">
              <tr
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 group">
                <th scope="row" class="flex items-center px-3 py-1.5 whitespace-nowrap text-gray-900 dark:text-white">

                  <div
                    class="inline-block w-8 h-8 text-center text-black shadow-soft-2xl dark:shadow-current rounded-lg bg-slate-800 dark:to-gray-850 ${transformeChannelName(item.channel)} transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                    <i
                      class="${iconByChannel(item.channel)} text-xs text-white my-2 transition-all duration-250 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]"></i>
                  </div>

                  <div class="pl-3">
                    <span class="text-xs font-semibold">${transformeChannelName(item.channel)}</span>
                  </div>
                </th>

                <td class="px-3 py-4">
                  <div class="flex items-center">
                    <div class="h-2.5 w-2.5 rounded-sm bg-red-500 mr-2"></div> <span
                      class="leading-tight text-xs">${item.rascunhos} </span>
                  </div>
                </td>
                <td class="px-3 py-4">
                  <div class="flex items-center">
                    <div class="h-2.5 w-2.5 rounded-sm bg-yellow-300 mr-2"></div> <span
                      class="leading-tight text-xs">${item.agendados} </span>
                  </div>
                </td>
                <td class="px-3 py-4">
                  <div class="flex items-center">
                    <div class="h-2.5 w-2.5 rounded-sm bg-green-500 mr-2"></div> <span
                      class="leading-tight text-xs">${item.publicados} </span>
                  </div>
                </td>
                <td class="px-3 py-4">
                  <span class="leading-tight text-xs">${item.mercado ?? 0 | currency:'pt-BR'}</span>
                </td>
                <td class="px-3 py-4">
                  <span class="leading-tight text-xs">${item.copymagico ?? 0 | currency:'pt-BR'}</span>
                </td>
                <td class="px-3 py-4">
                  <span class="leading-tight text-xs">${item.economia ?? 0 | currency:'pt-BR'}</span>
                </td>
              </tr>
            </template>

          </tbody>
        </table>
      </div>

      <!-- <div class="mt-6 flex justify-end">
        <a href="/projects" class="btn-secondary">Projetos</a>
      </div> -->

    </div>
  </div>
  <!-- end::card -->
</div>