<import from="@components/spinner-button/spinner-button-component" />

<div class="bg-white rounded-lg shadow dark:bg-gray-700 max-h-[calc(100vh-5rem)] h-full w-[50vw] overflow-y-auto">

  <!-- begin::header -->
  <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Registrar contato ou tentativa de contato</h3>
    <button type="button" click.trigger="$dialog.cancel()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
  </div>
  <!-- end::header -->

  <!-- begin::body -->
  <form class="flex flex-col gap-4 p-4" novalidate="novalidate">
    <!-- beggin::date -->
    <div class="flex-1">
      <label for="date" class="form-label">Data</label>
      <validation-container>
        <input type="datetime-local" id="date" value.bind="date & validate" class="form-input" autocomplete="off">
      </validation-container>
    </div>
    <!-- end::date -->

    <!-- begin::channel -->
    <div class="flex-1">
      <label for="type" class="form-label">Canal de contato</label>
      <validation-container>
        <select id="type" value.bind="channel & validate" class="form-input">
          <option model.bind="null">Selecione</option>
          <option repeat.for="item of channelData" model.bind="item.value">${item.text}</option>
        </select>
      </validation-container>
    </div>
    <!-- end::channel -->

    <!-- begin::success -->
    <div class="flex-1">
      <label for="date" class="form-label">Obteve sucesso na interação?</label>
      <validation-container>
        <div class="flex gap-4">
          <div class="flex flex-1 items-center ps-4 border border-gray-300 rounded dark:border-gray-600 cursor-pointer">
            <input id="success-1" type="radio" model.bind="true" checked.bind="success" name="success" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="success-1" class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Sim</label>
          </div>
          <div class="flex flex-1 items-center ps-4 border border-gray-300 rounded dark:border-gray-600 cursor-pointer">
            <input id="success-2" type="radio" model.bind="false" checked.bind="success" name="success" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="success-2" class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Não</label>
          </div>
        </div>
      </validation-container>
    </div>
    <!-- end::success -->

    <!-- beggin::date -->
    <div class="flex-1">
      <label for="date" class="form-label">Observação</label>
      <validation-container>
        <textarea type="text" id="observation" value.bind="observation & validate" autocomplete="off" maxlength="200" class="form-input h-[calc(100%-2rem)]"></textarea>
      </validation-container>
    </div>
    <!-- end::date -->
  </form>
  <!-- end::body -->

  <!-- begin::footer -->
  <div class="flex items-center p-4 justify-end space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
    <button type="button" click.trigger="closeAndSubmit()" class="btn-primary-small">Fechar</button>
    <spinner-button-component label="Salvar" trigger-function.bind="() => handleSave()"></spinner-button-component>
  </div>
  <!-- end::footer -->
</div>