<import from='@components/promise-bind/error/error-component' />
<import from='@components/promise-bind/pending/pending-component' />

<section>

  <form id="kt_inbox_compose_form">
    <div class="block">
      <div id="kt_inbox_form_editor-${quillId}" class="kt_inbox_form_editor scrollbar-thin scrollbar-thumb-rounded-md scrollbar-thumb-gray-300"></div>
    </div>
  </form>

  <!-- Main modal -->
  <div id="kt_modal_2" data-modal-target="kt_modal_2" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-2xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            Insert image
          </h3>
          <button type="button" click.trigger="hideModal()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="kt_modal_2">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
            <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <!-- upload -->
              <li class="mr-2" role="presentation">
                <button click.trigger="changeCurrentImageTabIndex(0)" class="inline-block p-4 border-b-2 rounded-t-lg" id="upload-tab" data-tabs-target="#upload" type="button" role="tab" aria-controls="upload" aria-selected="false">Upload</button>
              </li>
              <!-- pexels -->
              <li class="mr-2" role="presentation">
                <button click.trigger="changeCurrentImageTabIndex(1)" class="inline-block p-4 border-b-2 rounded-t-lg" id="pexels-tab" data-tabs-target="#pexels" type="button" role="tab" aria-controls="pexels" aria-selected="false">Pexels</button>
              </li>
              <!-- ai -->
              <li class="mr-2" role="presentation">
                <button click.trigger="changeCurrentImageTabIndex(2)" class="inline-block p-4 border-b-2 rounded-t-lg" id="ai-tab" data-tabs-target="#ai" type="button" role="tab" aria-controls="ai" aria-selected="false">AI Generated</button>
              </li>
            </ul>
          </div>
          <div id="myTabContent">

            <!-- upload -->
            <div class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="upload" role="tabpanel" aria-labelledby="upload-tab">
              <input change.trigger="filesChanged($event)" files.bind="selectedFiles" class="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file" type="file" name="file" accept="image/*">
            </div>
            <!-- /upload -->

            <!-- pexels -->
            <div class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="pexels" role="tabpanel" aria-labelledby="pexels-tab">

              <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input type="text" value.bind="query & debounce:300" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for images">
              </div>

              <div if.bind="!query">
                <div class="flex flex-wrap items-center justify-center mt-6">
                  <p class="mt-4 text-sm text-gray-500 dark:text-gray-400">Search for images by typing in a keyword above.</p>
                </div>
              </div>

              <div if.bind="query">
                <template promise.bind="searchImagesFromPexels(query)">

                  <template pending>
                    <div class="flex flex-col justify-center items-center p-4">
                      <div role="status">
                        <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                      </div>
                      <span class="text-sm font-semibold text-gray-400 dark:text-gray-400 pt-2">Loading...</span>
                    </div>
                  </template>

                  <template then.from-view="searchImagesFromPexelsResponse">
                    <div class="grid grid-cols-3 gap-2 mt-3">
                      <div repeat.for="parameter of searchImagesFromPexelsResponse.images" class="grow shrink-0 basis-0">

                        <div class="${selectedImageId == parameter.id ? 'bg-blue-100 text-blue-500' : ''} cursor-pointer items-center flex flex-col justify-center border border-dashed border-gray-400 dark:border-gray-200 hover:bg-blue-50 hover:text-blue-500 dark:hover:text-blue-500 text-gray-400 dark:text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2" click.trigger="selectPexelsImage(parameter.id)" for="kt_radio_buttons_2_option_2">
                          <img src="${parameter.small}">
                          <div class="d-flex w-100 mt-2">
                            <span><i class="fa fa-expand"></i></span>
                            <span class="flex-grow-1">${parameter.width}x${parameter.height}</span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </template>

                  <template catch.from-view="error">
                    <div class="flex flex-col justify-center items-center p-4">
                      <span class="text-sm font-semibold text-red-600 dark:text-red-600">Ocorreu um erro 🤕</span>
                      <span class="text-sm font-medium italic text-red-600 dark:text-red-600 pt-2">${error}</span>
                    </div>
                  </template>

                </template>
              </div>

            </div>
            <!-- /pexels -->

            <!-- ai -->
            <div class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="ai" role="tabpanel" aria-labelledby="ai-tab">

              <form novalidate="novalidate" submit.trigger="triggerAIImageSearch()">
                <div class="relative w-full">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <input type="text" value.bind="aiImageSearchQuery" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-12 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Pesquisar imagens">

                  <div click.trigger="triggerAIImageSearch()" class="absolute inset-y-0 right-0 flex items-center my-0.5 mr-3 cursor-pointer px-2.5 py-2 text-center rounded-full hover:bg-primary-600 hover:text-white">
                    <i class="fa-solid fa-wand-sparkles"></i>
                  </div>
                </div>
              </form>

              <template if.bind="!callTriggerAIImageSearch">
                <div class="flex flex-wrap items-center justify-center mt-6">
                  <p class="mt-4 text-sm text-gray-500 dark:text-gray-400">Pesquise imagens digitando uma palavra-chave acima.</p>
                </div>
              </template>
              <template else>
                <template promise.bind="generateImageFromPrompt(refresher)">

                  <template pending>
                    <pending-component></pending-component>
                  </template>

                  <template then.from-view="generateImageFromPromptResponse">
                    <div class="p-3">
                      <img class="w-full" src="${generateImageFromPromptResponse.url}">
                    </div>
                  </template>

                  <template catch.from-view="error">
                    <error-component error.bind="error"></error-component>
                  </template>

                </template>
              </template>

            </div>
            <!-- /ai -->

          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex justify-end items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button data-modal-hide="kt_modal_2" type="button" click.trigger="hideModal()" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Close</button>
          <button data-modal-hide="kt_modal_2" type="button" disabled.bind="(changeCurrentImageTabIndex == 0 && !selectedImageUrl) || (changeCurrentImageTabIndex == 1 && !selectedImageId)" click.trigger="insertImage()" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Inserir</button>
        </div>
      </div>
    </div>
  </div>

</section>