<import from='./../../components/header/header-component' />
<import from='@components/promise-bind/pending/pending-component' />
<import from='./components/image-carousel/image-carousel-component' />

<section class="p-4 flex flex-col justify-between h-full">

  <div class="flex flex-col">
    <header-component title="Produto" subtitle="Detalhes do produto de interesse"></header-component>

    <div class="flex flex-col gap-3 mt-6">
      <div class="flex flex-row gap-1">
        <div class="flex flex-col flex-1">
          <span class="text-[10px] text-gray-700 dark:text-gray-400">Nome:</span>
          <span class="text-xs text-gray-900 dark:text-gray-200 font-semibold line-clamp-1">${product.alias ?? 'N/A'}</span>
        </div>
      </div>

      <div class="flex flex-row gap-1">
        <div class="flex flex-col flex-1">
          <span class="text-[10px] text-gray-700 dark:text-gray-400">URL:</span>
          <span class="text-xs text-gray-900 dark:text-gray-200 font-semibold line-clamp-1">${product.linkConversion ?? 'N/A'}</span>
        </div>
      </div>

      <div class="flex flex-row gap-1">
        <div class="flex flex-col flex-1">
          <span class="text-[10px] text-gray-700 dark:text-gray-400">De:</span>
          <span class="text-xs text-gray-900 dark:text-gray-200 font-semibold">${product.price | currency}</span>
        </div>

        <div class="flex flex-col flex-1">
          <span class="text-[10px] text-gray-700 dark:text-gray-400">por:</span>
          <span class="text-xs text-gray-900 dark:text-gray-200 font-semibold">${product.salePrice | currency}</span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <image-carousel-component show.bind="product" type.bind="product.type"></image-carousel-component>
  </div>

</section>