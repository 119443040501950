<section>

  <label class="form-label">${label}</label>
  <div draggable="true" dragover.trigger="dragOver($event)" dragleave.trigger="dragLeave($event)" drop.trigger="drop($event)">
    <label for="${id}" class="dropzone flex flex-col justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">

      <!-- beggin::placeholder -->
      <div show.bind="dropzoneFile.length == 0" class="flex flex-col items-center justify-center pt-5 pb-6">
        <svg aria-hidden="true" class="w-8 h-8 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
        </svg>
        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center"><span class="font-semibold">Click to upload</span> or drag and drop</p>
        <p class="text-xs text-gray-500 dark:text-gray-400 text-center">SVG, PNG, JPG or GIF</p>
      </div>
      <!-- end::placeholder -->

      <!-- beggin::preview -->
      <div class="flex">
        <template repeat.for="item of dropzoneFile">
          <div id="${item.id}" class="dz-preview dz-processing dz-image-preview">

            <!-- beggin::image -->
            <div class="dz-image">
              <img src="${item.file}" class="max-w-full h-full object-cover block">
            </div>
            <!-- end::image -->

            <!-- beggin::progress -->
            <div class="dz-progress">
              <span class="dz-upload" data-dz-uploadprogress="" style.bind="`width: ${item.upload.progress}%;`"></span>
            </div>
            <!-- end::progress -->

            <!-- beggin::success -->
            <div class="dz-success-mark">
              <svg width="54" height="54" viewBox="0 0 54 54" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2071 29.7929L14.2929 25.7071C14.6834 25.3166 15.3166 25.3166 15.7071 25.7071L21.2929 31.2929C21.6834 31.6834 22.3166 31.6834 22.7071 31.2929L38.2929 15.7071C38.6834 15.3166 39.3166 15.3166 39.7071 15.7071L43.7929 19.7929C44.1834 20.1834 44.1834 20.8166 43.7929 21.2071L22.7071 42.2929C22.3166 42.6834 21.6834 42.6834 21.2929 42.2929L10.2071 31.2071C9.81658 30.8166 9.81658 30.1834 10.2071 29.7929Z"></path>
              </svg>
            </div>
            <!-- edn::success -->

            <!-- beggin::delete -->
            <div click.trigger="removeFile(item.id)" class="absolute hidden border-1 rounded-lg px-2 py-0.5 -top-1 -right-2 z-50 bg-danger-600 dz-delete shadow-lg shadow-gray-600">
              <i class="fa-solid fa-trash text-sm text-white"></i>
            </div>
            <!-- end::delete -->
          </div>
        </template>
      </div>
      <!-- end::preview -->

    </label>
  </div>

  <input id="${id}" type="file" change.trigger="filesChanged($event)" accept="image/*" class="hidden" multiple.bind="multiple" />
  <input type="text" value.bind="currentFiles" class="hidden" />
</section>