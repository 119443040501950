import { IRouter } from '@aurelia/router';
import { IEventAggregator, bindable } from 'aurelia';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { categoryOfProduct, natureOfProduct } from 'common/data';
import { IValidationController } from '@aurelia/validation-html';
import { AlertModalType } from '@components/alert-modal/events/alert-modal-event';
import { AlertModalComponent } from '@components/alert-modal/alert-modal-component';
import { IProductsService } from '../services/products-service';
import { StorageUploadFileResponse } from '@nhost/nhost-js';
import { nhost } from 'lib/nhost';

export class ProductPage {

  private characteristicTagify: Tagify;
  private benefitsTagify: Tagify;
  private wordKeysTagify: Tagify;

  @bindable imageOneFile: File;
  @bindable imageOneFileId: string;

  @bindable imageTwoFile: File;
  @bindable imageTwoFileId: string;

  @bindable imageThreeFile: File;
  @bindable imageThreeFileId: string;

  @bindable imageFourFile: File;
  @bindable imageFourFileId: string;

  private brandId: string;
  private productId: string;
  private type: string;
  private alias: string;
  private category: string;
  private linkConversion: string;
  private price: number;
  private salePrice: number;
  private characteristics: string[] = [];
  private benefits: string[] = [];
  private wordKeys: string[] = [];
  private testimonials: string[] = [];
  private description: string;

  private natureOfProductData = natureOfProduct;
  private categoryOfProductData = categoryOfProduct;

  constructor(
    @IRouter private router: IRouter,
    @IEventAggregator readonly ea: IEventAggregator,
    @IProductsService private service: IProductsService,
    @IValidationRules private validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) private validationController: IValidationController,
  ) {
    validationRules
      .on(this)
      .ensure('alias')
      .required().withMessage('Nome do produto é obrigatório')

      .ensure('type')
      .required().withMessage('Natureza do produto é obrigatório')

      .ensure('category')
      .required().withMessage('Categoria é obrigatório')
  }

  async loading(params: { id: string; brandId: string; }) {
    this.productId = params.id;
    this.brandId = params.brandId;

    if (this.productId) {
      await this.getProductById(this.productId);
    }
  }

  attached() {
    if (this.productId) {
      this.characteristicTagify.addTags(this.characteristics);
      this.benefitsTagify.addTags(this.benefits);
      this.wordKeysTagify.addTags(this.wordKeys);
    }
  }

  private async getProductById(id: string) {
    const product = await this.service.getProductById(id);

    this.type = product.type;
    this.alias = product.alias;
    this.category = product.category;
    this.linkConversion = product.linkConversion;
    this.price = product.price;
    this.salePrice = product.salePrice;
    this.characteristics = JSON.parse(product.characteristics);
    this.benefits = JSON.parse(product.benefits);
    this.wordKeys = JSON.parse(product.wordKeys);
    this.testimonials = JSON.parse(product.testimonials);
    this.description = product.description;
    this.imageOneFileId = product.imageOneId;
    this.imageTwoFileId = product.imageTwoId;
    this.imageThreeFileId = product.imageThreeId;
    this.imageFourFileId = product.imageFourId;
  }

  private handleNavigateToPage() {
    window.open(this.linkConversion, '_blank', 'noopener,noreferrer');
  }

  private async handleUpsertProduct() {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    if (this.productId) {
      await this.handleUpdateProduct();
    } else {
      await this.handleSaveProduct();
    }
  }

  private async handleSaveProduct() {
    AlertModalComponent.show({
      event: this.ea,
      payload: {
        label: 'Salvar produto',
        message: 'Gostaria de salvar a produto?',
        type: AlertModalType.SUCCESS,
        toastMessage: 'Produto salvo com sucesso!',
        confirmFunction: async () => {
          let imageOneFileResponse: StorageUploadFileResponse | null = null;
          let imageTwoFileResponse: StorageUploadFileResponse | null = null;
          let imageThreeFileResponse: StorageUploadFileResponse | null = null;
          let imageFourFileResponse: StorageUploadFileResponse | null = null;

          if (this.imageOneFile) {
            imageOneFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageOneFile,
              ...this.imageOneFileId && { fileId: this.imageOneFileId }
            });
          }

          if (this.imageTwoFile) {
            imageTwoFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageTwoFile,
              ...this.imageTwoFileId && { fileId: this.imageTwoFileId }
            });
          }

          if (this.imageThreeFile) {
            imageThreeFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageThreeFile,
              ...this.imageThreeFileId && { fileId: this.imageThreeFileId }
            });
          }

          if (this.imageFourFile) {
            imageFourFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageFourFile,
              ...this.imageFourFileId && { fileId: this.imageFourFileId }
            });
          }

          const { id } = await this.service.insertProduct({
            alias: this.alias,
            type: this.type,
            category: this.category,
            // linkConversion: this.linkConversion,
            price: this.price,
            salePrice: this.salePrice,
            characteristics: JSON.stringify(this.characteristicTagify.value.map((item: any) => item.value)),
            benefits: JSON.stringify(this.benefitsTagify.value.map((item: any) => item.value)),
            wordKeys: JSON.stringify(this.wordKeysTagify.value.map((item: any) => item.value)),
            description: this.description,


            imageOneId: imageOneFileResponse ? imageOneFileResponse.fileMetadata.id : this.imageOneFileId,
            imageTwoId: imageTwoFileResponse ? imageTwoFileResponse.fileMetadata.id : this.imageTwoFileId,
            imageThreeId: imageThreeFileResponse ? imageThreeFileResponse.fileMetadata.id : this.imageThreeFileId,
            imageFourId: imageFourFileResponse ? imageFourFileResponse.fileMetadata.id : this.imageFourFileId,
          });

          if (!this.productId) {
            this.router.load(`/product/${id}`);
          }
        }
      }
    });
  }

  private async handleUpdateProduct() {
    AlertModalComponent.show({
      event: this.ea,
      payload: {
        label: 'Salvar produto',
        message: 'Gostaria de salvar a produto?',
        type: AlertModalType.SUCCESS,
        toastMessage: 'Produto salvo com sucesso!',
        confirmFunction: async () => {
          let imageOneFileResponse: StorageUploadFileResponse | null = null;
          let imageTwoFileResponse: StorageUploadFileResponse | null = null;
          let imageThreeFileResponse: StorageUploadFileResponse | null = null;
          let imageFourFileResponse: StorageUploadFileResponse | null = null;

          if (this.imageOneFile) {
            imageOneFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageOneFile,
              ...this.imageOneFileId && { fileId: this.imageOneFileId }
            });
          }

          if (this.imageTwoFile) {
            imageTwoFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageTwoFile,
              ...this.imageTwoFileId && { fileId: this.imageTwoFileId }
            });
          }

          if (this.imageThreeFile) {
            imageThreeFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageThreeFile,
              ...this.imageThreeFileId && { fileId: this.imageThreeFileId }
            });
          }

          if (this.imageFourFile) {
            imageFourFileResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.imageFourFile,
              ...this.imageFourFileId && { fileId: this.imageFourFileId }
            });
          }

          await this.service.updateProductById({
            id: this.productId,
            alias: this.alias,
            type: this.type,
            category: this.category,
            // linkConversion: this.linkConversion,
            price: this.price,
            salePrice: this.salePrice,
            characteristics: JSON.stringify(this.characteristicTagify.value.map((item: any) => item.value)),
            benefits: JSON.stringify(this.benefitsTagify.value.map((item: any) => item.value)),
            wordKeys: JSON.stringify(this.wordKeysTagify.value.map((item: any) => item.value)),

            imageOneId: imageOneFileResponse ? imageOneFileResponse.fileMetadata.id : this.imageOneFileId,
            imageTwoId: imageTwoFileResponse ? imageTwoFileResponse.fileMetadata.id : this.imageTwoFileId,
            imageThreeId: imageThreeFileResponse ? imageThreeFileResponse.fileMetadata.id : this.imageThreeFileId,
            imageFourId: imageFourFileResponse ? imageFourFileResponse.fileMetadata.id : this.imageFourFileId,
          });
        }
      }
    });
  }
}