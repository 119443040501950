<import from="./components/filter/filter-component" />
<import from="./components/order-by/order-by-component" />

<import from='@components/promise-bind/pending/pending-component' />

<div class="p-3 mb-6">

  <!-- beggin::filter -->
  <div class="flex flex-row justify-between items-center mx-4 mb-5">
    <span class="text-sm text-gray-700 dark:text-gray-400">Total: <span
        class="font-semibold text-gray-900 dark:text-white">${config.total}</span> </span>

    <div class="flex flex-row items-center gap-2">
      <order-by-component if.bind="config.orderBy.length" order-by.bind="config.orderBy"
        handle-order-by-callback.bind="() => handleOrderBy()"></order-by-component>
      <filter-component if.bind="config.filters.length" filters.bind="config.filters"
        handle-filter-callback.bind="() => handleFilter()"></filter-component>
    </div>
  </div>
  <!-- end::filter -->

  <template if.bind="isLoading">
    <pending-component></pending-component>
  </template>
  <template else>
    <au-slot name="body" expose.bind="config"></au-slot>
  </template>

  <!-- beggin::pagination -->
  <div class="flex flex-row items-center justify-between mt-10 mb-6 mx-4">
    <span class="text-sm text-gray-700 dark:text-gray-400">Página <span
        class="font-semibold text-gray-900 dark:text-white">${config.currentPage}</span> de <span
        class="font-semibold text-gray-900 dark:text-white">${totalPages}</span></span>

    <nav>
      <ul class="inline-flex -space-x-px text-sm">

        <li>
          <a click.trigger="goToPage(1)"
            class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer">
            <span class="sr-only">Previous</span>
            <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 1 1 5l4 4" />
            </svg>
            <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 1 1 5l4 4" />
            </svg>
          </a>
        </li>

        <li>
          <a click.trigger="goToPage(config.currentPage - 1)"
            class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer">
            <span class="sr-only">Previous</span>
            <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M5 1 1 5l4 4" />
            </svg>
          </a>
        </li>

        <!-- beggin::Display visible page numbers using a loop -->
        <li repeat.for="page of visiblePages">
          <button click.trigger="goToPage(page)"
            class="flex items-center justify-center px-3 h-8 leading-tight border border-gray-300 ${page === config.currentPage ? 'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}">${page}</button>
        </li>
        <!-- end::Display visible page numbers using a loop -->

        <li>
          <a click.trigger="goToPage(config.currentPage + 1)"
            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer">
            <span class="sr-only">Next</span>
            <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 9 4-4-4-4" />
            </svg>
          </a>
        </li>

        <li>
          <a click.trigger="goToPage(totalPages)"
            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer">
            <span class="sr-only">Next</span>
            <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 9 4-4-4-4" />
            </svg>
            <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 9 4-4-4-4" />
            </svg>
          </a>
        </li>

      </ul>
    </nav>
  </div>
  <!-- end::pagination -->

</div>