<import from='@components/card-title/card-title-component' />
<import from="@components/spinner-button/spinner-button-component" />

<import from="./components/calendar/calendar-component" />
<import from="./components/courses/courses-component" />
<import from="./components/carousel/carousel-component" />
<import from="./components/categories/categories-component" />
<import from="./components/status/status-component" />
<import from="./components/projects/projects-component" />
<import from="./components/funnel/funnel-component" />
<import from="./components/brands/brands-component" />
<import from="./components/audiences/audiences-component" />
<import from="./components/products/products-component" />
<import from="./components/user-integration/user-integration-component" />

<section>

	<card-title-component title-id="dashboard"></card-title-component>

	<div class="flex flex-wrap mb-6 gap-6">

		<!-- begin::carousel -->
		<div class="w-full max-w-full">
			<carousel-component></carousel-component>
		</div>
		<!-- end::carousel -->

		<!-- begin:: -->
		<div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full max-w-full">
			<brands-component></brands-component>
			<audiences-component></audiences-component>
			<products-component></products-component>
			<user-integration-component></user-integration-component>
		</div>
		<!-- end:: -->

		<!-- begin:: -->
		<div class="grid grid-cols-1 lg:grid-cols-6 gap-4 w-full max-w-full">
			<calendar-component class="lg:col-span-4"></calendar-component>
			<courses-component class="lg:col-span-2 lg:h-60"></courses-component>
		</div>
		<!-- end:: -->

	</div>

</section>