import { DI } from 'aurelia';

enum StructureItemTypeEnum {
  link = 'link',
  section = 'section',
  dropdown = 'dropdown'
}

interface IStructureItem {
  id?: string;
  type: StructureItemTypeEnum;
  displayName?: string;
  icon?: string;
  soon?: boolean;
  itens?: IStructureItem[];
}

export interface INavBarStructure {
  description: IStructureItem[];
}

class NaviBarStructure implements INavBarStructure {
  public description: IStructureItem[] = [
    {
      type: StructureItemTypeEnum.section,
      displayName: 'Area de trabalho',
      itens: [
        {
          id: '455c4f52-8803-43f0-8318-ec83cf621614',
          type: StructureItemTypeEnum.link,
          displayName: 'dashboard',
          icon: 'fas fa-solid fa-house',
        },
        {
          id: '413604b7-b7ce-4bf8-b423-d4959ec05922',
          type: StructureItemTypeEnum.link,
          displayName: 'calendar',
          icon: 'fa-solid fa-calendar-days',
        },
        {
          id: '2626615c-18f4-4ea4-98ff-9274f949842c',
          type: StructureItemTypeEnum.link,
          displayName: 'projects',
          icon: 'fa-solid fa-calendar-days',
        },
        {
          id: '2590c54a-7003-47de-8212-a4f5f3fb2fd2',
          type: StructureItemTypeEnum.link,
          displayName: 'media',
          icon: 'fa-solid fa-photo-film',
        },
        {
          id: 'fa01a9da-f605-42f3-a475-53db1c0bc0a6',
          type: StructureItemTypeEnum.link,
          displayName: 'socialMedia',
          icon: 'fa-solid fa-photo-film',
        },
        {
          type: StructureItemTypeEnum.dropdown,
          displayName: 'brand',
          icon: 'fas fa-copyright',
          itens: [
            {
              id: '577485d9-d9bd-4baf-9261-d0ff848ccc12',
              type: StructureItemTypeEnum.link,
              displayName: 'brandGuide',
              icon: 'fas fa-copyright',
            },
            {
              id: 'b6138e27-eea3-4d2f-ac80-f83ed0629979',
              type: StructureItemTypeEnum.link,
              displayName: 'archetype',
              icon: 'fa-solid fa-users-between-lines',
            },
            {
              id: '3bd4995a-031e-4b22-a639-168bcc4eadcf',
              type: StructureItemTypeEnum.link,
              displayName: 'audiences',
              icon: 'fa-solid fa-users-viewfinder',
            }
          ]
        },
      ]
    },
    {
      type: StructureItemTypeEnum.section,
      displayName: 'Ferramentas de venda',
      itens: [
        {
          id: 'b18962b1-30ab-418a-8c47-2cbd4aa17c92',
          type: StructureItemTypeEnum.link,
          displayName: 'productsAndServices',
          icon: 'fa-solid fa-boxes-packing',
        },
        {
          id: 'c5287143-67e9-48f5-a49d-af64b1a4aac9',
          type: StructureItemTypeEnum.link,
          displayName: 'opportunityManagement',
          icon: 'fa-solid fa-city',
        },
        {
          id: '441f1095-0eed-4629-97cb-4557feb4eb01',
          type: StructureItemTypeEnum.link,
          displayName: 'textsAndArticles',
          icon: 'fa-regular fa-newspaper',
        },
        {
          id: 'c73706b5-dbc9-4559-b64e-1897283025da',
          type: StructureItemTypeEnum.link,
          displayName: 'posts',
          icon: 'fa-solid fa-blog',
        },
        {
          type: StructureItemTypeEnum.dropdown,
          displayName: 'creations',
          icon: 'fa-solid fa-paint-brush',
          itens: [
            {
              id: '19c62f81-f5eb-4ebf-8547-54dc7b834dde',
              type: StructureItemTypeEnum.link,
              displayName: 'images',
              icon: 'fa-regular fa-image',
            },
            {
              id: '25e6c4b8-2470-4ee9-9606-067a3f3a758c',
              type: StructureItemTypeEnum.link,
              displayName: 'videos',
              icon: 'fa-solid fa-video',
              soon: true,
            },
            {
              id: '9c412438-bd8b-486a-bd03-5e8cd8a58b0a',
              type: StructureItemTypeEnum.link,
              displayName: 'adverts',
              icon: 'fa-solid fa-rectangle-ad',
              soon: true,
            },
          ]
        },
      ]
    },

    {
      type: StructureItemTypeEnum.section,
      displayName: 'Central de ensino',
      itens: [
        {
          id: '529597a8-1c0d-47bf-9a78-e41a8c5ff663',
          type: StructureItemTypeEnum.link,
          displayName: 'faq',
          icon: 'fas fa-question-circle',
        },
        {
          id: '19236484-aed9-40e2-8782-2d45c5567fc5',
          type: StructureItemTypeEnum.link,
          displayName: 'tutorials',
          icon: 'fas fa-lightbulb',
        }
      ]
    },
  ];

}

export const INavBarStructure = DI.createInterface<INavBarStructure>(x => x.singleton(NaviBarStructure));
