<import from='@components/card-title/card-title-component' />
<import from='@components/generic-list/generic-list-component' />
<import from='@components/promise-bind/pending/pending-component' />

<section>
  <card-title-component title-id="marcas">
    <div au-slot="actions">
      <input type="file" id="input-upload-media" name="file" accept="image/*" class="hidden" files.bind="selectedFiles" change.trigger="filesChanged($event)">
      <label for="input-upload-media" class="btn btn-primary cursor-pointer">
        <i class="fa fa-upload"></i>
        <span class="ml-2">Upload</span>
      </label>
    </div>
  </card-title-component>
  <div class="card p-4">
    <generic-list-component config.bind="config">
      <div au-slot="body">
        <div class="columns-1 gap-5 sm:columns-2 sm:gap-4 md:columns-3 lg:columns-4 [&>div:not(:first-child)]:mt-4">
          <template repeat.for="item of $host.data">
            <let img-url.bind="null" err.bind="null"></let>
            <template promise.bind="getImage(item.fileId)">
              <template pending>
                <pending-component class="flex justify-center items-center h-full"></pending-component>
              </template>
              <template then.from-view="imgUrl">

                <div class="relative h-auto">
                  <img src="${imgUrl ?? 'https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80'}" alt="Imagem" class="transition duration-500 ease-in-out object-cover object-center rounded-lg shadow-md w-full">
                  <div class="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-50 transition duration-500 ease-in-out flex justify-center items-center gap-2 opacity-0 hover:opacity-100 rounded-lg">
                    <button click.trigger="downloadImage(item.fileId)" class="border-2 border-white px-4 py-2 rounded-md transition-colors duration-300 hover:bg-white hover:text-black text-white">
                      <i class="fa fa-download"></i>
                    </button>
                    <button click.trigger="deleteUserMedia(item.id)" class="border-2 border-white px-4 py-2 rounded-md transition-colors duration-300 hover:bg-white hover:text-black text-white">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>


              </template>
            </template>
          </template>
        </div>

      </div>
    </generic-list-component>

  </div>

</section>