<import from='./../../components/header/header-component' />

<section class="p-4">
  <header-component title="Histórico" subtitle="Lista de registros importantes"></header-component>

  <div class="flex flex-col gap-4 mt-6">

    <!-- begin::created_at -->
    <div class="flex flex-row gap-1">
      <div class="flex flex-col flex-1">
        <span class="text-[10px] text-gray-700 dark:text-gray-400">Criado em:</span>
        <span class="text-xs text-gray-900 dark:text-gray-200 font-semibold line-clamp-1">${opportunity.created_at | date:'pr-BR'}</span>
      </div>
    </div>
    <!-- end::created_at -->

    <div class="flex flex-col gap-2">

      <template repeat.for="item of 8">

        <!-- begin::card -->
        <div class="flex flex-row w-full rounded-lg bg-white dark:bg-gray-800 shadow-soft-xs shadow-gray-300 dark:shadow-current border border-solid border-gray-200 dark:border-gray-700 px-4 py-2.5 gap-4">
          <div class="flex flex-col">
            <span class="text-xs text-gray-900 dark:text-gray-200 font-semibold">Kids Basic Karate</span>
            <span class="text-[10px] text-gray-700 dark:text-gray-400 line-clamp-1">${opportunity.created_at | timeago} por Rafael Desordi</span>
          </div>
        </div>
        <!-- end::card -->

      </template>

    </div>

  </div>
</section>