import { ICustomElementController } from "@aurelia/runtime-html";
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationController } from '@aurelia/validation-html';
import { IValidationRules, ValidateInstruction } from '@aurelia/validation';
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';
import { ICreateProjectService, SizeObject } from './services/create-project-service';
import { ModelTypes } from '@zeus';
import { IRouter } from "@aurelia/router";

export class CreateProjectDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private selectedBrandId: string;
  private brandsData: ModelTypes["brands"][] = [];

  private selectedAudienceId: string;
  private audiencesData: ModelTypes["audiences"][] = []

  private selectedProductId: string;
  private productsData: ModelTypes["products"][] = []

  private selectedDisplayDimensionId: string;
  private displayDimensionData: SizeObject[] = []

  private flexText: string;
  private selectedTemplateId: string;
  private selectedImage: string;
  private currentImageTabIndex: number = 0;

  constructor(
    @IDialogDom private dialogDom: DefaultDialogDom,
    @IValidationRules private validationRules: IValidationRules,
    @ICreateProjectService private service: ICreateProjectService,
    @newInstanceForScope(IValidationController) private validationController: IValidationController,
    @IRouter private router: IRouter
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  async activate(model?: any) {
    this.getData();
  }

  private async getData(): Promise<void> {
    const [audiences, products, brands, formats] = await Promise.all([
      this.service.getAudiences(),
      this.service.getProducts(),
      this.service.getBrands(),
      this.service.getFormats()
    ]);

    this.audiencesData = audiences;
    this.productsData = products;
    this.brandsData = brands;
    this.displayDimensionData = formats;

    setTimeout(() => {
      if (this.brandsData.length > 0) {
        this.selectedBrandId = this.brandsData[0].id;
      }
    }, 1);
  }

  private async getAvailableDisplays(selectedDisplayDimensionId: string): Promise<ModelTypes["pTemplate"][]> {
    if (!selectedDisplayDimensionId) return;

    const dimmension = this.displayDimensionData.find(x => x.id === selectedDisplayDimensionId);

    const result = await this.service.getTemplates({ height: dimmension.height, width: dimmension.width });

    return result;
  }

  async close() {
    this.$dialog.ok();
  }

  async handleGenerateProject() {
    const result = await this.service.createNewProject(
      {
        brandId: this.selectedBrandId,
        productId: this.selectedProductId,
        audienceId: this.selectedAudienceId,
        templateId: this.selectedTemplateId,
        image: this.selectedImage,
        artDirectional: this.flexText,
      }
    )

    if (result) {
      this.close();

      await this.router.load(`/editor/${result.projectId}`, {
        data: {
          brandId: this.selectedBrandId,
          productId: this.selectedProductId,
          audienceId: this.selectedAudienceId,
          prompt: this.flexText,
        }
      });
    }
  }
}