import { nhost } from 'lib/nhost';
import { watch } from "@aurelia/runtime-html";
import { BindingMode, bindable } from 'aurelia';
import { ICreateProjectService } from '@components/create-project-dialog/services/create-project-service';

interface IMediaProps {
  id: string;
  url: string;
}

export class ProductComponent {
  @bindable productId: string;
  @bindable({ mode: BindingMode.twoWay }) selectedImage: string;

  private selectedImageId: string;
  private productMedia: IMediaProps[] = [];

  constructor(@ICreateProjectService private service: ICreateProjectService) { }

  attached() {
    if (this.productId) {
      this.getMedia(this.productId);
    }
  }

  @watch('productId')
  handleAccountSubscription(newValue: string, oldValue: string) {
    this.productMedia = [];

    this.selectedImage = null;
    this.selectedImageId = null;

    if (newValue && newValue !== oldValue) {
      this.getMedia(newValue);
    }
  }

  private async getMedia(productId: string): Promise<void> {
    if (!productId) return;

    let filesIds: string[] = [];

    const product = await this.service.getProductsById(productId);

    if (!product) return;

    filesIds = [product.imageOneId, product.imageTwoId, product.imageThreeId, product.imageFourId];

    for (const fileId of filesIds) {
      if (!fileId) continue;

      const imageUrl = this.getImageUrl(fileId);

      this.productMedia.push({
        id: fileId,
        url: imageUrl
      });
    }
  }

  private getImageUrl(fileId: string): string {
    if (!fileId) return;

    const url = nhost.storage.getPublicUrl({
      fileId: fileId,
      width: 200,
    });

    return url
  }

  private selectMedia(id: string) {
    this.selectedImage = this.productMedia.find(i => i.id === id).url;
    this.selectedImageId = id;
  }
}