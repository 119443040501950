<import from='@components/card-status/card-status-component' />
<import from="@components/spinner-button/spinner-button-component" />

<import from='./components/slider/slider-component' />
<import from='./components/models/models-component' />
<import from='./components/art-style/art-style-component' />
<import from='./components/aspect-ratio/aspect-ratio-component' />

<section>
  <card-status-component>
    <div au-slot="status"></div>
    <div au-slot="actions" class="flex gap-2">
      <spinner-button-component label="Gerar" btn-class="btn-primary-small !px-10" trigger-function.bind="() => handleGenerate()"></spinner-button-component>
    </div>
  </card-status-component>

  <div class="flex flex-col lg:flex-row gap-4 mb-6 mt-8">

    <!-- begin:inputs -->
    <aside class="card w-full lg:w-[40%] h-fit">

      <form class="p-4" autocomplete="off">

        <div class="flex flex-col gap-4 mb-6">

          <!-- begin::brand -->
          <div class="flex-1">
            <label for="brand" class="form-label">Marca</label>
            <select id="brand" value.bind="selectedBrandId" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of brands" model.bind="item.id">${item.brandName}</option>
            </select>
          </div>
          <!-- end::brand -->

          <template if.bind="selectedBrandId">

            <!-- begin::product -->
            <!-- <div class="flex-1">
              <label for="product" class="form-label">Produtos</label>
              <select id="product" value.bind="selectedProductId" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of products" model.bind="item.id">${item.alias}</option>
              </select>
            </div> -->
            <!-- end::product -->

            <!-- begin::audience -->
            <!-- <div class="flex-1">
              <label for="audience" class="form-label">Audiências</label>
              <select id="audience" value.bind="selectedAudienceId" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of audiences" model.bind="item.id">${item.brandName}</option>
              </select>
            </div> -->
            <!-- end::audience -->

            <!-- begin::prompt -->
            <div class="flex-1">
              <label t="imagine.prompt" for="prompt" class="form-label">Prompt</label>
              <textarea id="prompt" name="prompt" value.bind="prompt" autocomplete="off" class="form-input !h-20 hide-scrollbar resize-none"></textarea>
            </div>
            <!-- end::prompt -->

          </template>

          <!-- begin::Aspect ratio -->
          <div class="flex-1">

            <label t="imagine.aspectRatio" for="prompt" class="form-label">Aspect ratio</label>
            <aspect-ratio-component selected-aspect-ratio.bind="selectedAspectRatio"></aspect-ratio-component>
          </div>
          <!-- end::Aspect ratio -->

          <!-- begin::Models -->
          <div class="flex-1">
            <label t="imagine.models" for="prompt" class="form-label">Models</label>
            <models-component selected-model.bind="selectedModel"></models-component>
          </div>
          <!-- end::Models -->

          <!-- begin::Art styles -->
          <div class="flex-1">
            <label t="imagine.artStyles" for="prompt" class="form-label">Art Styles</label>
            <art-style-component selected-atr-style.bind="selectedAtrStyle"></art-style-component>
          </div>
          <!-- end::Art styles -->

          <!-- begin::Negative Prompt -->
          <!-- <div class="flex-1">
            <label for="prompt" class="form-label">Negative Prompt</label>
            <textarea id="negative_prompt" name="negative_prompt" value.bind="negativePrompt" autocomplete="off" class="form-input !h-20 hide-scrollbar resize-none" placeholder="Enter text here..."></textarea>
          </div> -->
          <!-- end::Negative Prompt -->

          <!-- begin::CFG Scale -->
          <!-- <div class="flex-1">
            <div class="flex items-center justify-between mb-2">
              <label for="cfgScale" class="form-label items-center">CFG Scale</label>
              <span class="grid h-5 w-10 mb-2 place-items-center rounded-md bg-[#f6f6f6] px-2 text-xs font-medium leading-[18px] text-neutral-900 transition-all duration-500 dark:bg-neutral-800 dark:text-neutral-0">${cfgScale}</span>
            </div>

            <slider-component value.bind="cfgScale" min="3" max="15" step="0.5"></slider-component>
          </div> -->
          <!-- end::CFG Scale -->

          <!-- begin::Step Scale -->
          <!-- <div class="flex-1">
            <div class="flex items-center justify-between mb-2">
              <label for="stepScale" class="form-label items-center">Step Scale</label>
              <span class="grid h-5 w-10 mb-2 place-items-center rounded-md bg-[#f6f6f6] px-2 text-xs font-medium leading-[18px] text-neutral-900 transition-all duration-500 dark:bg-neutral-800 dark:text-neutral-0">${stepScale}</span>
            </div>

            <slider-component value.bind="stepScale" min="30" max="50" step="1"></slider-component>
          </div> -->
          <!-- end::Step Scale -->

          <!-- begin::Seed -->
          <!-- <div class="flex-1">
            <label for="seed" class="form-label">Seed</label>
            <input type="number" name="seed" value.bind="seed" placeholder="Enter a seed" class="form-input">
          </div> -->
          <!-- end:Seed -->

        </div>

      </form>

    </aside>
    <!-- end:inputs -->

    <!-- begin:outputs -->
    <div class="w-full lg:w-[60%] h-fit flex flex-col gap-4">

      <!-- begin::info -->
      <!-- <div class="card p-4 flex gap-3">
        <div class="flex-1">
          <label class="form-label" for="">Label</label>
          <input class="form-input" readonly type="text">
        </div>
        <div class="flex-1">
          <label class="form-label" for="">Label</label>
          <input class="form-input" readonly type="text">
        </div>
        <div class="flex-1">
          <label class="form-label" for="">Label</label>
          <input class="form-input" readonly type="text">
        </div>
      </div> -->
      <!-- end::info -->

      <!-- begin::ilustration -->
      <div class="card">
        <div class="flex h-full min-h-[520px] max-h-[520px] w-full flex-1 items-center justify-center my-10">

          <template if.bind="ilustration">
            <div class="overflow-hidden rounded bg-[#f6f6f6] object-contain transition-all duration-500 dark:bg-neutral-900 max-h-[inherit] max-w-[inherit]" style.bind="`aspect-ratio: ${selectedAspectRatio.value ? selectedAspectRatio.value.replace(':','/') : '1/1'}`">
              <img src.bind="ilustration" class="w-full h-full overflow-hidden rounded object-contain transition-all duration-500" />
            </div>
          </template>
          <template else>
            <div class="overflow-hidden rounded bg-[#f6f6f6] object-contain transition-all duration-500 dark:bg-neutral-900 max-h-[inherit] max-w-[inherit]" style.bind="`aspect-ratio: ${selectedAspectRatio.value ? selectedAspectRatio.value.replace(':','/') : '1/1'}`">
              <svg width="500" height="500" fill="none" style.bind="`aspect-ratio: ${selectedAspectRatio.value ? selectedAspectRatio.value.replace(':','/') : '1/1'}`">
                <g clip-path="url(#IllustrationBackground_a)" class="dark:opacity-50">
                  <path fill="currentColor" class="text-[#E0E0E1] transition-all duration-500 dark:text-neutral-700" d="m100.648 320.362-26.545 13.856a.461.461 0 0 0-.136.711l19.578 22.673a.462.462 0 0 0 .433.152l28.884-5.361a.462.462 0 0 0 .293-.719l-21.915-31.168a.463.463 0 0 0-.592-.144ZM195.013 327.117l-33.971 28.1c-.165.136-.247.204-.291.298-.044.093-.044.2-.044.413v24.251c0 .442 0 .662.138.798s.359.132.8.125l312.452-5.06c.108-.002.162-.002.213-.015.052-.013.1-.038.196-.087l24.819-12.801c.244-.126.366-.189.433-.299.067-.111.067-.248.067-.522v-78.294c0-1.981 0-2.971-.38-3.039-.381-.068-.723.861-1.409 2.719l-11.898 32.237c-.172.466-.258.7-.456.774-.198.074-.416-.045-.852-.283l-8.916-4.86c-.348-.19-.522-.285-.695-.243-.173.042-.285.205-.509.533l-28.782 42.112c-.123.18-.185.27-.276.322-.091.053-.199.061-.416.078l-80.503 6.177a.934.934 0 0 1-.273.001.947.947 0 0 1-.247-.115l-47.725-26.62c-.221-.124-.332-.185-.453-.185s-.231.063-.452.188l-37.714 21.37c-.259.147-.389.221-.529.211-.14-.01-.257-.102-.493-.286l-20.61-16.047c-.124-.096-.186-.144-.259-.17-.073-.025-.152-.025-.309-.025h-9.017c-.19 0-.284 0-.37-.035-.085-.035-.152-.103-.285-.237l-24.015-24.179c-.331-.332-.496-.499-.698-.491-.202.007-.354.185-.659.541l-15.777 18.415c-.274.32-.411.48-.597.498-.185.017-.35-.114-.68-.377l-7.394-5.901c-.281-.224-.421-.336-.585-.335-.164.002-.302.116-.579.345Z"></path>
                  <path fill="currentColor" class="text-[#C4C4C5] transition-all duration-500 dark:text-neutral-600" d="m50.162 307.411-26.89 33.338c-.255.315-.381.472-.37.652.013.181.16.32.453.598l56.994 54.019c.173.164.26.246.37.277.11.031.226.006.459-.044l43.443-9.291c.167-.036.25-.053.332-.041.083.012.157.053.307.135l72.849 40.127c.293.161.439.242.592.22.152-.022.27-.14.507-.376l15.3-15.318c.171-.171.256-.257.367-.291.111-.033.229-.01.467.038l31.825 6.338c.072.014.108.021.144.023a.985.985 0 0 0 .146-.012l98.647-11.74c.066-.007.098-.011.131-.01.033 0 .065.006.13.017l49.377 8.238c.15.025.225.037.298.026.074-.012.142-.047.277-.116l77.283-39.799c.416-.214.624-.322.678-.516.054-.195-.07-.394-.317-.792l-30.838-49.665c-.335-.539-.502-.808-.751-.815-.248-.006-.429.254-.791.774l-20.473 29.427c-.121.174-.182.261-.27.312-.089.052-.194.061-.405.08l-99.1 8.925c-.215.019-.322.029-.419-.007-.098-.036-.173-.113-.324-.267l-13.803-14.09c-.376-.383-.564-.575-.786-.552-.222.023-.366.25-.653.704l-14.799 23.361c-.178.282-.268.423-.412.477-.144.055-.304.008-.625-.084l-55.537-16.089c-.185-.053-.277-.08-.37-.069-.093.011-.176.059-.343.154l-28.081 15.975c-.191.109-.287.163-.393.17-.106.007-.208-.035-.412-.118l-60.43-24.662c-.122-.049-.182-.074-.247-.081a.97.97 0 0 0-.258.026l-49.213 8.41c-.249.042-.373.064-.487.023-.113-.04-.197-.135-.363-.325l-41.803-47.723c-.335-.383-.503-.575-.718-.571-.215.005-.375.203-.695.6Z"></path>
                  <ellipse cx="276.117" cy="274.795" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.259" ry="1.675"></ellipse>
                  <ellipse cx="230.129" cy="152.124" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.215" ry="1.616"></ellipse>
                  <ellipse cx="74.627" cy="232.456" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".868" ry="1.154"></ellipse>
                  <ellipse cx="93.699" cy="46.815" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".787" ry="1.047"></ellipse>
                  <ellipse cx="455.001" cy="89.271" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".472" ry=".628"></ellipse>
                  <ellipse cx="35.355" cy="71.265" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".472" ry=".628"></ellipse>
                  <ellipse cx="29.457" cy="241.625" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".472" ry=".628"></ellipse>
                  <ellipse cx="305.212" cy="196.128" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".63" ry=".837"></ellipse>
                  <ellipse cx="106.669" cy="72.859" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".63" ry=".837"></ellipse>
                  <ellipse cx="140.684" cy="319.397" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".63" ry=".837"></ellipse>
                  <ellipse cx="152.485" cy="132.416" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".63" ry=".837"></ellipse>
                  <ellipse cx="440.581" cy="40.08" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".63" ry=".837"></ellipse>
                  <ellipse cx="273.973" cy="140.265" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".63" ry=".837"></ellipse>
                  <ellipse cx="156.305" cy="330.939" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".63" ry=".837"></ellipse>
                  <ellipse cx="228.218" cy="238.227" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".694" ry=".923"></ellipse>
                  <ellipse cx="249.958" cy="328.544" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.259" ry="1.675"></ellipse>
                  <ellipse cx="21.564" cy="314.232" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.259" ry="1.675"></ellipse>
                  <ellipse cx="128.955" cy="20.856" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.102" ry="1.465"></ellipse>
                  <ellipse cx="36.713" cy="182.025" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".787" ry="1.047"></ellipse>
                  <ellipse cx="156.971" cy="209.688" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.102" ry="1.465"></ellipse>
                  <ellipse cx="372.674" cy="91.955" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.102" ry="1.465"></ellipse>
                  <ellipse cx="278.61" cy="12.084" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.102" ry="1.465"></ellipse>
                  <ellipse cx="453.314" cy="297.553" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".521" ry=".693"></ellipse>
                  <ellipse cx="490.532" cy="179.926" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".944" ry="1.256"></ellipse>
                  <ellipse cx="488.796" cy="13.26" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".944" ry="1.256"></ellipse>
                  <ellipse cx="214.585" cy="55.273" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".944" ry="1.256"></ellipse>
                  <ellipse cx="116.892" cy="289.136" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".787" ry="1.047"></ellipse>
                  <ellipse cx="67.1" cy="134.113" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".63" ry=".837"></ellipse>
                  <ellipse cx="451.233" cy="212.142" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".868" ry="1.154"></ellipse>
                  <ellipse cx="383.719" cy="67.867" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.041" ry="1.385"></ellipse>
                  <ellipse cx="396.218" cy="312.096" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx=".694" ry=".923"></ellipse>
                  <ellipse cx="375.071" cy="196.252" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.417" ry="1.884"></ellipse>
                  <ellipse cx="350.399" cy="326.408" fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" rx="1.041" ry="1.385"></ellipse>
                  <path fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" d="M80.464 393.281 1.559 318.478c-.667-.632-1-.948-1.28-.828-.279.12-.279.579-.279 1.498v179.929c0 .435 0 .653.135.788s.353.135.788.135h197.154c.435 0 .653 0 .788-.135s.135-.353.135-.788v-73.991c0-.267 0-.4-.065-.509-.064-.109-.181-.173-.415-.301l-73.084-39.958c-.149-.081-.223-.122-.305-.134-.082-.012-.165.006-.331.041l-43.508 9.289c-.233.05-.35.074-.46.044-.109-.031-.195-.113-.368-.277Z"></path>
                  <path fill="currentColor" class="text-[#B6B6B7] transition-all duration-500 dark:text-neutral-500" d="m214.456 394.177-90.135 104.294c-.57.66-.854.989-.731 1.259.124.269.559.269 1.43.269l374.057-.267c.435 0 .653 0 .788-.135.135-.136.135-.353.135-.788v-162.18c0-.722 0-1.084-.234-1.216-.234-.133-.544.053-1.164.424l-102.269 61.225c-.15.09-.225.135-.309.15-.084.015-.169-.002-.341-.035l-49.318-9.562a.908.908 0 0 0-.151-.023.983.983 0 0 0-.151.014l-98.616 13.639a1.023 1.023 0 0 1-.169.016c-.042-.002-.083-.012-.166-.031l-31.749-7.348c-.265-.061-.397-.092-.519-.052s-.211.142-.388.347Z"></path>
                  <path fill="currentColor" class="text-[#9B9C9E] transition-all duration-500 dark:text-neutral-400" d="m214.854 471.978-59.019 26.252c-1.554.691-2.331 1.037-2.254 1.403.078.366.929.365 2.63.364l342.865-.249c.435 0 .652 0 .787-.135.136-.136.136-.353.136-.788V382.744c0-.63 0-.946-.208-1.083-.207-.137-.497-.013-1.077.234l-79.149 33.673c-.133.056-.199.084-.27.091-.071.007-.141-.008-.282-.038l-71.061-15.017c-.164-.034-.246-.052-.327-.04-.081.012-.155.051-.302.131l-132.469 71.283Z"></path>
                </g>
                <defs>
                  <clipPath id="IllustrationBackground_a">
                    <rect width="500" height="500" fill="currentColor" class="text-neutral-100 transition-all duration-500" rx="4"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </template>

        </div>
      </div>
      <!-- end::ilustration -->

    </div>
    <!-- end:outputs -->

  </div>

</section>