<div id="accordion-${id}" class="border border-gray-200 dark:border-gray-700 rounded-md card !shadow-sm">
  <h2 id="accordion-${id}-heading" class="rounded-md">
    <span class="${status === 'completed' ? 'bg-[#E4F2FC]' : status === 'disapproved' ? 'bg-[#FFEBEF]' : ''} flex flex-row items-center justify-between w-full h-[56px] p-4 font-medium text-base text-left hover:bg-gray-50 dark:hover:bg-gray-800 rounded-md cursor-pointer" aria-expanded="false" aria-controls="accordion-${id}-body">
      <div class="flex flex-col">
        <au-slot name="title">
          <span class="font-light text-sm text-gray-900 dark:text-white line-clamp-1">${title}</span>
          <span class="font-extralight text-xs text-gray-400 dark:text-gray-400">${subtitle}</span>
        </au-slot>
      </div>

      <div class="flex items-center gap-2">
        <au-slot name="actions"></au-slot>

        <template switch.bind="status">
          <i case="completed" class="fa-solid fa-check mr-3 text-blue-700"></i>
          <i case="disapproved" class="fa-solid fa-triangle-exclamation mr-3 text-red-700"></i>
          <i case="locked" class="fa-solid fa-lock mr-3 text-gray-700"></i>
          <i case="unlocked" class="fa-solid fa-unlock mr-3 text-gray-700"></i>
          <span default-case></span>
        </template>

        <svg id="accordion-${id}-icon" data-accordion-icon class="w-5 h-5 shrink-0 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
        </svg>
      </div>

    </span>
  </h2>

  <!-- begin::body -->
  <div id="accordion-${id}-body" class="hidden" aria-labelledby="accordion-${id}-heading">
    <div class="p-4 bg-white dark:bg-gray-900 rounded-md">
      <au-slot name="body"></au-slot>
    </div>
  </div>
  <!-- end::body -->
</div>