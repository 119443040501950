<import from='@components/promise-bind/error/error-component' />
<import from='@components/promise-bind/pending/pending-component' />

<div class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="ai" role="tabpanel" aria-labelledby="ai-tab">

  <form novalidate="novalidate" submit.trigger="triggerAIImageSearch()">
    <div class="relative w-full">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
        </svg>
      </div>
      <input type="text" value.bind="query" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-12 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Pesquisar imagens">

      <div click.trigger="triggerAIImageSearch()" class="absolute inset-y-0 right-0 flex items-center my-0.5 mr-3 cursor-pointer px-2.5 py-2 text-center rounded-full hover:bg-primary-600 hover:text-white">
        <i class="fa-solid fa-wand-sparkles"></i>
      </div>
    </div>
  </form>

  <template if.bind="!callTriggerAIImageSearch">
    <div class="flex flex-wrap items-center justify-center mt-6">
      <p class="mt-4 text-sm text-gray-500 dark:text-gray-400">Pesquise imagens digitando uma palavra-chave acima.
      </p>
    </div>
  </template>
  <template else>
    <template promise.bind="generateImageFromPrompt(refresher)">

      <template pending>
        <pending-component></pending-component>
      </template>

      <template then.from-view="generateImageFromPromptResponse">
        <!-- <div class="p-3">
          <img class="w-full" src="${generateImageFromPromptResponse.url}">
        </div> -->
        <div class="grid grid-cols-2 gap-2 mt-3">
          <div repeat.for="image of generateImageFromPromptResponse.images" class="grow shrink-0 basis-0">

            <div class="${selectedImageId == image.id ? 'border-4 border-orange-500 bg-orange-100' : ''} cursor-pointer items-center flex flex-col justify-center border border-gray-200 dark:border-gray-200 hover:bg-blue-50 hover:text-blue-500 dark:hover:text-blue-500 text-gray-400 dark:text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm p-1" click.trigger="selectImage(image.id)" for="kt_radio_buttons_2_option_2">
              <img src="${image.small ?? image.url}" class="w-full rounded-lg overflow-hidden">
            </div>

          </div>
        </div>
      </template>

      <template catch.from-view="error">
        <error-component error.bind="error"></error-component>
      </template>

    </template>
  </template>

</div>