import { DI } from 'aurelia';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { $, ModelTypes, project_constraint, project_update_column, pTemplate_constraint, pTemplate_update_column } from '@zeus';

export interface IPolotnoService {
  getProject(id: string): Promise<ModelTypes['project']>;
  upsertProject(command: ModelTypes['project_insert_input']): Promise<ModelTypes['project']>;
  saveTemplate(command: ModelTypes['pTemplate_insert_input']): Promise<ModelTypes['pTemplate']>;
}

class PolotnoService implements IPolotnoService {

  constructor(
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient
  ) { }

  async getProject(id: string): Promise<ModelTypes['project']> {
    const result = await this.zeusClient.chain('query')({
      project_by_pk: [
        {
          id: id,
        },
        {
          id: true,
          type: true,
          alias: true,
          template: [{ path: '$' }, true],
        },
      ]
    });

    return result.project_by_pk as ModelTypes['project'];
  }

  async upsertProject(command: ModelTypes['project_insert_input']): Promise<ModelTypes['project']> {
    const result = await this.zeusClient.chain('mutation')({
      insert_project_one: [
        {
          object: command,
          on_conflict: {
            constraint: project_constraint.project_pkey,
            update_columns: [
              project_update_column.alias,
              project_update_column.caption,
              project_update_column.creditPrice,
              project_update_column.rawText,
              project_update_column.type,
              project_update_column.template,
            ]
          }
        },
        {
          id: true,
        }
      ]
    });

    return result.insert_project_one as ModelTypes['project'];;
  }

  async saveTemplate(command: ModelTypes['pTemplate_insert_input']): Promise<ModelTypes['pTemplate']> {
    const result = await this.zeusClient.chain('mutation')({
      insert_pTemplate_one: [
        {
          object: {
            ...command,
            json: $('json', 'jsonb')
          },
          // on_conflict: {
          //   constraint: pTemplate_constraint.pTemplate_pkey,
          //   update_columns: [
          //     pTemplate_update_column.titleMaxLength,
          //     pTemplate_update_column.descriptionMaxLength,
          //     pTemplate_update_column.subtitleMaxLength,
          //     pTemplate_update_column.category,
          //   ]
          // }
        },
        {
          id: true,
        },
      ]
    }, { variables: { json: command.json } });

    return result.insert_pTemplate_one as ModelTypes['pTemplate'];
  }

}

export const IPolotnoService = DI.createInterface<IPolotnoService>(x => x.singleton(PolotnoService));